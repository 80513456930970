@import "ui/styles/2-tools/tools.action";

.Preview_top {
	padding: 0.6rem 1rem;
	background-color: lemonchiffon;
	text-align: center;
	color: black;
	position: sticky;
	top: 0;
	z-index: 9999;
}

.Preview_link {
	@include action;
	color: darkslateblue;
}
