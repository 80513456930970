@import "ui/styles/2-tools/tools.media-query.scss";

.Event {
	margin: var(--spacing--4xl) 0;
}

.Event_heading {
	margin-bottom: var(--spacing--lg);
}

.Event_manchet {
	font-size: var(--font-size--xl);
	margin-bottom: var(--spacing--5xl);
}

.Event_metaBox {
	background-color: var(--theme-30);
	padding: var(--spacing--lg);
	border-radius: .6rem;
	margin-bottom: var(--spacing--5xl);
}

.Event_metaBoxContent {
	margin-bottom: var(--spacing--sm);
	@include mq("md") {
		margin-bottom: 0;
	}
	:first-letter {
		text-transform: uppercase;
	}
}

.Event_metaBoxCategory {
	text-decoration: underline;
	font-weight: var(--font-weight--semibold);
	margin-bottom: var(--spacing--md);
}

.Event_metaBoxPrice{
	font-weight: var(--font-weight--bold);
	margin-top: var(--spacing--sm);
}

.Event_metaBoxInner {
	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}
}

.Event_signUpLink {
	margin-top: var(--spacing--lg);
}