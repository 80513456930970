.ArticleHeader {
	margin-top: var(--spacing--xl);
	margin-bottom: var(--spacing--xl);
	margin-left: -.2rem;
}

.ArticleHeader_heading {
	width: 100%;
	margin-bottom: var(--spacing--xs);
}

.ArticleHeader_lead {
	margin-top: var(--spacing--lg);
	max-width: 75%;
	font-size: var(--font-size--xl);
}

.ArticleHeader_date > p {
	margin-top: var(--spacing--lg);
	background-color: var(--color-concern--10);
	padding: var(--spacing--sm) var(--spacing--lg);
	border-radius: var(--border--2xl);
	max-width: fit-content;
}

.ArticleHeader_contactAnchorLink {
	margin-top: var(--spacing--sm);
	color: var(--color-black);
}
