@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
.EmphasizedContentCard {
	position: relative;
	height: 100%;
	margin-bottom: calc(var(--grid-gutter) * 2);
	padding: var(--spacing--lg);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-block-start: var(--spacing--5xl);
	--bg-color: var(--theme-10);
	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	&.bg--light {
		--bg-color: (--theme-20);
	}

	.bg--dark & {
		background: var(--theme-50);
	}

	.bg--dark & {
		background: var(--color-white);
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0em;
		left: 0;
		width: 100%;
		height: 80%;
		z-index: -1;
		@include mq("md") {
			&___row,
			&___reverseRow {
				bottom: 25.3rem;
				max-height: 72.4rem;
			}
		}
		@include mq("sm") {
			&___row,
			&___reverseRow {
				bottom: 0;
				max-height: 100rem;
			}
		}
		background: var(--bg-color);
	}
	@include mq("lg") {
		margin-block-end: var(--spacing--3xl);

		&___row {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-end;
			padding: 0;
			gap: 3rem;
			margin-block-start: 20rem;
		}
		&___rowReverse {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			align-items: flex-end;
			padding: 0px;
			gap: 3rem;
			margin-block-start: 20rem;
		}
		&.EmphasizedContentCard___row,
		&.EmphasizedContentCard___rowReverse {
			&::after {
				content: none;
			}
		}
	}
	&___bordered {
		border: var(--border--sm) solid var(--color-gradient--10);
	}
	&___padded {
		padding: var(--spacing--md);
	}
	&___padded-x2 {
		padding: var(--spacing--xl);
	}
}
.EmphasizedContentCard_box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: var(--spacing--xl) 0 var(--spacing--5xl) 0;
	gap: 0.8rem;
	width: 100%;

	@include mq("md"){
		.EmphasizedContentCard___rowBlock & {
			padding: var(--spacing--5xl) 0 var(--spacing--5xl) 0;
		}
	}

	@include mq("lg") {
		max-width: 90%;
		padding-top: var(--spacing--5xl);
		background: transparent;
		flex: none;
		order: 0;
		flex-grow: 0;

		.EmphasizedContentCard___row & {
			background: var(--theme-20);
			align-items: flex-end;
		}
		.EmphasizedContentCard___rowReverse & {
			background: var(--theme-20);
			align-items: flex-start;
		}
	}
}
.EmphasizedContentCard___link {
	display: block;
	text-decoration: none;
	color: var(--color-black);
	padding: var(--spacing--lg);
	border-radius: var(--corner-size--xs);
	box-shadow: 0 0 0.2em var(--shadow-color--mild);
}
.EmphasizedContentCard___link:hover,
.EmphasizedContentCard___link:focus {
	background-color: var(--color-gradient--5);

	@include mq("md") {
		background: var(--theme-50);
	}
}
.EmphasizedContentCard_textbox {

	.EmphasizedContentCard___rowBlock & {
			display: flex;
			flex-direction: column;
			padding: 0 var(--spacing--md) 0 var(--spacing--2xl);
			width: inherit;
	}

	@include mq("md"){
		.EmphasizedContentCard___rowBlock & {
			display: flex;
			flex-direction: row;
		}
	}

		/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("lg") {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding-top: var(--spacing--base);
		gap: 4rem;
		.EmphasizedContentCard___row &,
		.EmphasizedContentCard___rowReverse & {
			padding: 0px;
			gap: 2.4rem;
			display: flex;
			flex-direction: column;
			flex: none;
			order: 0;
			flex-grow: 0;
			min-height: 35rem;
		}
		.EmphasizedContentCard___row & {
			align-items: flex-start;
			position: relative;
			left: -10.5rem;
		}
		.EmphasizedContentCard___rowReverse & {
			padding-left: calc(var(--grid-gutter) * 2);
			align-items: flex-start;
			padding-left: 10.8rem;
		}
	}
}
.EmphasizedContentCard_heading {
	margin-bottom: 0.5em;
	width: 100%;
	max-width: 32.5rem;
	color: var(--color-concern);
	@include mq("lg") {
		font-size: var(--font-size--9xl);
		line-height: 6rem;
		max-width: 51.8rem;
		flex: none;
		order: 0;
		flex-grow: 0;
		.EmphasizedContentCard___row &,
		.EmphasizedContentCard___rowReverse & {
			padding: 0;
		}
	}
}

.EmphasizedContentCard_text {
	width: 100%;
	@include mq("lg") {
		width: 41rem;
	}
}
.EmphasizedContentCard_text p {
	margin-bottom: 1em;
	width: 100%;
	@include mq("lg") {
		width: 41rem;
	}
	flex: none;
	order: 1;
	flex-grow: 0;

	&:last-of-type {
		margin-bottom: 0;
	}
}
.EmphasizedContentCard_action {
	margin-top: 1em;
}

.EmphasizedContentCard_link {
	display: block;
	color: inherit;
	text-decoration: none;
}

.EmphasizedContentCard_media {
	display: block;
	margin-bottom: calc(var(--grid-gutter) * 2);
	max-width: 100%;
	width: 100%;
	max-height: 50rem;
	overflow: hidden;

	/**
	* Show the card using flexbox rows
	* AKA the Flag Object or Media Object
	* https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
	*/
	@include mq("lg") {
		max-width: 90%;

		.EmphasizedContentCard___row &,
		.EmphasizedContentCard___rowReverse & {
			position: absolute;
			max-width: 55%;
		}
		.EmphasizedContentCard___row & {
			left: -10.5rem;
			top: -6em;
		}
		.EmphasizedContentCard___rowReverse & {
			right: -10.5rem;
			top: -6rem;
		}
	}

	&___videoOnly {
		max-height: unset;
	}
}
.EmphasizedContentCard_mediabox {
	@include aspect-ratio(16, 9);

	@include mq("md") {
		.EmphasizedContentCard___row &,
		.EmphasizedContentCard___rowReverse & {
			@include aspect-ratio(4, 3);
		}
	}

	picture {
		max-height: 50rem;
	}
}

.EmphasizedContentCard_date {
	font-size: var(--font-size--sm);
	margin-bottom: 0.5em;
}

.EmphasizedContentCard_LinkBox {
	display: flex;
	.EmphasizedContentCard___rowBlock & {
		flex-direction: column;

		@include mq("md"){
			flex-direction: row;
		}
	}
}

.EmphasizedContentCard_button {
	margin-top: var(--spacing--xl);
	margin-right: calc(var(--grid-gutter) * 2);
	width: 100%;

	@include mq("md") {
		width: initial;
	}

	@include mq("lg") {
		margin-top: var(--spacing--2xl);
	}
}
.EmphasizedContentCard_button___secondary {
	margin-top: var(--spacing--base);
	margin-right: calc(var(--grid-gutter) * 2);

	@include mq("lg") {
		.EmphasizedContentCard___row & {
			color: var(--color-white);
			background-color: transparent;
			border-color: var(--color-white);

			&:hover {
				color: var(--color-primary);
				background-color: var(--color-white);
				border-color: var(--color-primary);
			}
		}

		.EmphasizedContentCard___rowBlock & {
			margin-top: var(--spacing--2xl);
		}
	}
}

.EmphasizedContentCard___rowBlock{
	padding: 0 0 0 0;
}

.EmphasizedContentCard___rowBlock > .EmphasizedContentCard_media{
	margin: 0 0 0 0;
	max-width: none;
}
