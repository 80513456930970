@import "ui/styles/2-tools/tools.media-query.scss";

.ScriptJobTeaserModule {
	padding: var(--spacing--3xl) 0;
}

.ScriptJobTeaserModule_headerContainer {
	padding-bottom: var(--spacing--2xl);
	@include mq("md") {
		padding-bottom: var(--spacing--5xl);
		display: flex;
		justify-content: space-between;
	}
}

.ScriptJobTeaserModule_seeAllLinkContainer {
	display: grid;
	align-items: center;
}

.ScriptJobTeaserModule_seeAllLink {
	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}

	&___mobile {
		width: 100%;
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}

.ScriptJobTeaserModule_Heading {
	@include mq("md") {
		font-size: var(--font-size--9xl);
	}
}