@import "ui/styles/2-tools/tools.media-query.scss";

.Container {
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--spacing--base);

	&___standard {
		max-width: var(--container-width--standard);
		padding: 0 var(--spacing--lg);
		
		@include mq("md") {
			padding: 0 var(--spacing--base);
		}
	}

	&___narrow {
		max-width: 96rem;
	}

	&___slim {
		max-width: var(--container-width--article-text);
	}

	&___skinny {
		max-width: 65rem;
	}
}
