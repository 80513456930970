@import "ui/styles/2-tools/tools.media-query.scss";

.HeroFrontpage {
	position: relative;
	width: 100%;
	height: 66vh;
	@include mq("md") {
		height: 100vh;
	}
	&___noImage {
		background-color: var(--color-black);
	}

	&___isContainingVideo {
		background-color: var(--theme-50);
		display: flex;
		flex-direction: column;
		height: 100%;

		@include mq('md'){
			height: 100vh;
			display: block;
			background-color: transparent;
		}

		&___isTextBlack {
			background-color: var(--theme-30);

			@include mq('md'){
				background-color: transparent;
			}


		}
	}
}

.HeroFrontpage_backgroundImage {
	position: absolute;
	width: 100%;
	height: 100%;

	@media print {
		display: none !important;
	}
}

.HeroFrontpage_overlay {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(65.27deg, rgba(34, 34, 34, 0.6) 0.32%, rgba(34, 34, 34, 0) 52.1%);

	@media print {
		display: none !important;
	}
}

.HeroFrontpage_videoContainer {
	display: flex;
	width: 100%;
	flex-direction: column-reverse;
	max-height: 70rem;
	object-fit: cover;
	padding-top: 0px;
	padding-bottom: 0px;
	overflow: hidden;
	
	@include mq("md") {
		position: absolute;
		width: 100%;
		max-height: 100vh;
	}

	@media print {
		display: none !important;
	}
}

.HeroFrontpage_content {
	position: absolute;
	z-index: 3;
	padding: var(--spacing--lg) var(--spacing--lg) var(--spacing--xl);
	bottom: 1rem;
	word-wrap: break-word;
	word-break: break-word;
	

	@include mq("md") {
		bottom: 18rem;
	}

	&___isContainingVideo {
		position: relative;
		z-index: 0;
		bottom: 0rem;
		padding: var(--spacing--xl) var(--spacing--lg) var(--spacing--xl);

		@include mq("md") {
			position: absolute;
			z-index: 3;
			padding: var(--spacing--lg) var(--spacing--lg) var(--spacing--xl);
			bottom: 18rem;
		}
	}
}

.HeroFrontpage_heading {
	color: var(--color-white);
	padding-bottom: var(--spacing--md);

	@include mq("md") {
		margin-left: -.5rem;
		font-size: var(--font-size--10xl);
		line-height: 7.8rem;
	}

	&___isTextBlack {
		color: var(--color-black);
	}
}

.HeroFrontpage_description {
	color: var(--color-white);
	max-width: 100%;

	@include mq("md") {
		max-width: 50%;
	}

	&___isTextBlack {
		color: var(--color-black);
	}
}
