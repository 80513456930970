@import "ui/styles/2-tools/_tools.media-query";

.UndersideList_heading,
.UndersideList_manchet {
	padding-bottom: var(--spacing--xl);

	@include mq("md") {
		padding: 0 var(--spacing--lg) var(--spacing--xl) var(--spacing--lg);
		padding-left: var(--grid-gutter);
		padding-bottom: var(--spacing--2xl);
		max-width: 80%;
	}
	@include mq("lg") {
		max-width: 65%;
	}
}

.UndersideList_heading {
	padding-top: var(--spacing--4xl);

	@include mq("md") {
		font-size: var(--font-size--9xl);
		padding-top: var(--spacing--6xl);
	}
}

.UndersideList_listMedia {
	margin-bottom: var(--border--sm);
}

.UndersideList_list {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--spacing--xl);
	padding-bottom: var(--spacing--2xl);

	@include mq("md") {
		padding-left: var(--grid-gutter);
		grid-template-columns: repeat(4, 1fr);
	}
}

.UndersideList_overlay {
	position: absolute;
	margin: 0 calc(var(--grid-gutter));
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.UndersideList_video {
	position: relative;

	&___mobile {
		padding-bottom: calc(var(--grid-gutter) * 10);
	}
}
