@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.LocalSearchBox {
	background-color: var(--color-gray--light);
	padding: var(--spacing--3xl) var(--spacing--xl);
	margin-bottom: 8rem;

	@include mq("md") {
		padding: 8rem 11rem;
		margin-bottom: var(--spacing--3xl);
	}
}

.LocalSearchBox_heading {
	padding-bottom: var(--spacing--xl);
	@include mq("md") {
		max-width: 50%;
		padding-bottom: var(--spacing--md);
	}
}

.LocalSearchBox_content {
	@include mq("md") {
		display: flex;
		gap: var(--spacing--md);
	}
}

.LocalSearchBox_inputContainer {
	position: relative;
	width: 100%;
}

.LocalSearchBox_input {
	position: relative;
	width: 100%;
	input {
		@include paragraph--lg;
		padding-left: var(--spacing--md);
		background: var(--color-white);
		outline: none;
		margin: 0;
		border-radius: 28px;
		border: 0.15rem solid var(--color-white);

		&:hover,
		&:focus {
			border: 0.15rem solid var(--color-primary) !important;
		}
	}
	&___isActive {
		input {
			border-radius: 28px 28px 0 0;
			border: 0.15rem solid var(--color-primary);
		}
	}

	@include mq("md") {
		margin-bottom: 0;
	}
}

.LocalSearchBox_inputFilter {
	margin-bottom: var(--spacing--base);
}

.LocalSearchBox_inputSearch {
	label {
		display: none;
	}
}

.LocalSearchBox_iconWrapper {
	transition: all var(--trans-time--shorter);
	width: 2.4rem;
	height: 2.4rem;
	margin: 0 auto;
	border: var(--border--sm);
	stroke: var(--color-primary);
}

.LocalSearchBox_buttons {
	display: flex;
	gap: var(--spacing--md);
	align-items: center;
	margin: var(--spacing--sm) 0;

	@include mq("md") {
		
		margin: 0;
	}
}
.Button__baseline{
	@include mq("md") {
		align-items: baseline;
	}
	
}
.LocalSearchBox_button {
	height: 5.6rem;

	span {
		display: flex;
		gap: var(--spacing--xs);
	}

	&:hover,
	&:focus {
		.LocalSearchBox_icon {
			stroke: var(--color-white);
		}
	}
}

.LocalSearchBox_resetButton {
	padding: 0;
	border: none;
	color: var(--color-primary);
	text-decoration: underline;

	&:hover,
	&:focus {
		border: none;
	}
}

.LocalSearchBox_resultsContainer {
	position: absolute;
	display: grid;
	align-items: center;
	padding: 0 var(--spacing--xs);
	grid-template-rows: repeat(5, 1fr);
	width: 100%;
	height: 300px;
	background-color: var(--color-white);
	//box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 28px 28px;
	margin-top: -2px;
	z-index: 2;
	border: 0.15rem solid var(--color-primary);
	border-top: none;

	@include mq("md", "max") {
		padding: var(--spacing--sm);
	}
}

.LocalSearchBox_result {
	@include paragraph--lg;
	color: var(--color-black);
	list-style: none;
	padding: var(--spacing--xs) var(--spacing--sm);
	cursor: pointer;
	border-radius: var(--corner-size--lg);

	&:hover {
		background-color: var(--color-concern--10);
	}

	@include mq("md", "max") {
		padding: var(--spacing--xs);
	}
}

.LocalSearchBox_filter {
	margin-top: var(--spacing--base);
	@include mq("md") {
		display: flex;
		gap: var(--spacing--md);
	}
}
