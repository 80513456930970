@import "ui/styles/2-tools/tools.media-query.scss";

.NewsList_buttonContainer {
	display: grid;
    justify-content: end;
}

.NewsList_button {
	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}

	&___mobile {
		width: 100%;
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}

.NewsList {
    max-width: var(--container-width--article-text);
    list-style: none;

    :last-child {
        border-bottom: none;
    }
}

.NewsList_item {
    border-bottom: .1rem solid var(--color-border--gray);
    padding-bottom: var(--spacing--xl);
    margin-bottom: var(--spacing--xl);
}

.NewsList_headingLink {
    display: block;
    margin-bottom: var(--spacing--sm);
}

.NewsList_heading {
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--xl);
	color: var(--color-concern);
	text-decoration: underline;
}

.NewsList_date {
    font-size: var(--font-size--md);

    &:first-letter {
		text-transform: uppercase;
	}

}

