@import "ui/styles/2-tools/tools.media-query.scss";

/* ------------------------------------ *\
		tools.typography-mixins
\* ------------------------------------ */

@mixin heading--xl-max-width {
	@include heading--xl;
	margin-bottom: 0.4m;
	width: 100%;
	max-width: 32.5rem;
	@include mq("lg") {
		font-size: var(--font-size--9xl);
		line-height: 6rem;
		max-width: 51.8rem;
	}
}

@mixin heading--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--7xl);
	line-height: var(--line-height--4xl);
	color: var(--color-concern);

	@include mq("lg") {
		font-size: var(--font-size--8xl);
		line-height: var(--line-height--5xl);
	}
}

@mixin heading--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--7xl);
	line-height: var(--line-height--4xl);
	color: var(--color-concern);

	@include mq("lg") {
		font-size: var(--font-size--10xl);
		line-height: var(--line-height--7xl);
	}
}

@mixin heading--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--6xl);
	line-height: var(--line-height--3xl);
	color: var(--color-black);
	text-decoration: underline;

	@include mq("lg") {
		font-size: var(--font-size--9xl);
		line-height: var(--line-height--6xl);
	}
}

@mixin heading--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--5xl);
	line-height: var(--line-height--xl);
	color: var(--color-black);

	@include mq("lg") {
		font-size: var(--font-size--6xl);
		line-height: var(--line-height--2xl);
	}
}

@mixin heading--xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--3xl);
	line-height: var(--line-height--lg);
	color: var(--color-black);

	@include mq("lg") {
		font-size: var(--font-size--4xl);
		line-height: var(--line-height--xl);
	}
}

@mixin heading--2xs {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--2xs);
	color: var(--color-black);

	@include mq("lg") {
		font-size: var(--font-size--xl);
		line-height: var(--line-height--sm);
	}
}

@mixin paragraph-manchet {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--medium);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--lg);
	letter-spacing: 1%;
	color: var(--color-black);

	@include mq("lg") {
		font-size: var(--font-size--xl);
		line-height: var(--line-height--xl);
		letter-spacing: 0.5%;
	}
}

@mixin paragraph--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--xl);
	letter-spacing: 0.5%;
	color: var(--color-black);
}

@mixin paragraph--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--lg);
	letter-spacing: 0.5%;
	color: var(--color-black);
}

@mixin paragraph-bold--lg {
	@include paragraph--lg;
	font-weight: var(--font-weight--bold);
}

@mixin paragraph--md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	color: var(--color-black);
}

@mixin paragraph-semibold--md {
	@include paragraph--md;
	font-weight: var(--font-weight--semibold);
}

@mixin paragraph--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	color: var(--color-black);
}

@mixin paragraph-caption--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--xs);
	letter-spacing: 0.07rem;
	color: var(--color-black);
}

@mixin paragraph-caption--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--2xs);
	letter-spacing: 0.07rem;
	color: var(--color-black);
}

@mixin formfield-label {
	@include paragraph-caption--sm;
	font-size: var(--font-size--md);
	line-height: var(--line-height--2xs);
	padding: 0 0 1rem 2.6rem;
	cursor: pointer;
}

@mixin bullet-list {
	margin: 0 0 var(--spacing--sm) var(--spacing--sm);
}
