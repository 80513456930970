.Spinner {
	width: 5.2rem;
	height: 5.2rem;
	animation: spinner 800ms linear infinite;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

.Spinner_screenReader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
	.Spinner {
		display: none;
	}

	.Spinner_loadingMsg {
		position: relative;
		width: auto;
		height: auto;
		padding: var(--spacing--xs);
		overflow: hidden;
		clip: auto;
		white-space: nowrap;
		border: var(--border--lg) solid var(--color-error);
		color: var(--color-light);
		font-size: var(--font-size--lg);
		background: var(--color-error);
	}
}
