@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.action.scss";

.Navigation {
	&___primary {
		@include mq("header") {
			margin: auto;
			margin-top: 1rem;
		}
	}
}

.Navigation_heading {
	@include hidden-absolute;
}

.Navigation_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;

	@include mq("md") {
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}
}

.Navigation_item {
	text-align: left;
	@include mq("md") {
		text-align: center;
	}
}

.Navigation_link {
	color: var(--color-text);
	text-decoration: none;
	white-space: nowrap;
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--lg);
	display: inline-block;
	padding: 0.75em 0;
	margin: 0.75em 0;
	border-bottom: var(--border--sm) solid transparent;
	cursor: pointer;

	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0.2rem;
		background: var(--color-concern);
		position: relative;
		top: 0.3rem;

		@include transition {
			transition: width var(--trans-time--shorter);
		}
	}

	&.isActive {
		@include pseudo-hover();

		&:after {
			width: 100%;
		}
	}

	&.isFrontPage {
		color: var(--color-white);
	}

	&:hover,
	&:focus {
		@include pseudo-hover();
		&:after {
			width: 100%;
			@include transition {
				transition: width var(--trans-time--shorter);
			}
		}
	}

	@include mq("md") {
		margin: 0;
		margin-left: 1.6rem;
		margin-right: 1.6rem;
		font-size: var(--font-size--lg);
		font-weight: var(--font-weight--normal);
		padding: 0.8rem 0;
	}
}
