@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldString {
	position: relative;
	display: flex;
	width: 100%;
	flex-flow: column;
	color: var(--color-gradient--80);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldString_input {
	@include input;
	margin: var(--spacing--2xs) 0;
	width: 100%;
	border: 0.15rem solid var(--color-border--gray);
	background: var(--color-gradient--5);
	-webkit-appearance: none;

	&::placeholder {
		color: var(--color-border--gray);
	}

	.isDisabled & {
		cursor: not-allowed;
	}
}

.FormfieldString_label {
	@include formfield-label;
}

.FormfieldString_input:not([disabled]):hover,
.FormfieldString_label:hover ~ .FormfieldString_input:not([disabled]),
.FormfieldString_input:focus {
	border: 0.15rem solid var(--color-primary);
}
