@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.ArticleMedia {
	margin-top: var(--spacing--md);
	margin-bottom: var(--spacing--md);
	width: 100%;
	max-width: var(--container-width--article-text);
}

.ArticleMedia_caption {
	font-style: italic;
	font-size: var(--font-size--sm);
	margin-top: var(--spacing--xs);
}