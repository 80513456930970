@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.VideoCard_Container {
	width: 100%;
	margin-bottom: var(--spacing--2xl);
}

.VideoCard {
	position: relative;
	max-width: var(--container-width--article-text);
	margin: 2rem 0 0;
	padding-top: var(--spacing--xl);
}

.VideoCard_heading {
	color: var(--color-black);
	margin-bottom: 1.8rem;
}

.VideoCard_text p {
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 0.3rem;
	}
}

.VideoCard_video div {
	margin-bottom: 1rem;
}
