@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.FindWay_container {
	@include mq("md", "max") {
		padding: 0 !important;
	}
}

.FindWay {
	padding: var(--spacing--4xl) var(--spacing--lg);

	@include mq("md") {
		padding: var(--spacing--5xl) var(--spacing--6xl);
		// transform: translateY(calc(-50% - 32px));
	}

	&___noBg {
		background-color: transparent;
	}
}

.FindWay_heading {
	padding-bottom: var(--spacing--xl);
	@include mq("md") {
		padding-bottom: 0;
	}
}

.FindWay_headingContainer {
	@include mq("md") {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		padding-bottom: var(--spacing--4xl);
	}
}

.FindWay_shortcuts {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--md);
}

.FindWay_alignMiddle {
	display: grid;
	align-items: center;
}


.FindWay_buttonMapLink {
	width: 100%;
	margin-bottom: var(--spacing--2xl);
	@include mq("md") {
		width: initial;
		margin-bottom: 0;
	}
}