@import "ui/styles/2-tools/tools.media-query.scss";

.Breadcrumb {

	display: none;
	@include mq("header") {
		padding-top:1rem;
		padding-bottom:1rem;
		display: block;
		background-color: var(--color-white);	
	}
	@media print {
		display: none !important;
	}
}

.Breadcrumb_inner {
	display: flex;
	justify-content: space-between;
	@include mq("lg", "max") {
		margin: 0 var(--spacing--base) !important;
	}
}

.Breadcrumb_list {
	&___isSecondLevel {
		padding-top: var(--spacing--lg);
	}
}

.Breadcrumb_item {
	display: inline-block;

	// Breadcrumb divider
	&:not(:last-child)::after {
		display: inline-block;
		content: "";
		vertical-align: middle;
		margin: 0 1rem;
		width: 0.6rem;
		height: 0.6rem;
		border-color: var(--color-black);
		border-style: solid;
		border-width: 0.15rem 0.15rem 0 0;
		transform: rotate(45deg);
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: var(--spacing--xs);
		a {
			font-weight: var(--font-weight--medium);
		}
	}
}

.Breadcrumb_url {
	display: inline-block;
	font-weight: var(--font-weight--light);
	color: var(--color-black);
	padding: 0 var(--spacing--xs);
	text-decoration: none;

	&:first-child {
		padding-left: 0;
	}

	&___current {
		font-weight: var(--font-weight--medium);
	}
}

.Breadcrumb_printButton {
	@include mq("md", "max") {
		display: none;
	}
}
