@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action";

.TopTasks_container {
	@include mq("md", "max") {
		padding: 0 !important;
	}
}

.TopTasks {
	padding: var(--spacing--2xl) var(--spacing--4xl);
	margin-bottom: var(--spacing--lg);

	&___standard {
		margin-top: 0 !important;
	}

	@include mq("md", "max") {
		padding: var(--spacing--2xl) var(--spacing--lg);
	}

	&___isFrontPage {
		position: relative;
		z-index: 10;

		@include mq("md", "max") {
			margin-top: 0 !important;
		}

		@include mq("md") {
			padding: var(--spacing--3xl) var(--spacing--6xl);
			margin-bottom: var(--spacing--6xl);
		}

		@media screen and (max-width: 900px) and (max-height: 600px) {
			transform: translateY(0);
			margin-bottom: 0;
			margin-top: -3.2rem;
		}
	}

	&___noBg {
		background-color: transparent;
	}
}

.TopTasks_heading {
	padding-bottom: var(--spacing--4xl);
	color: var(--color-primary--dark);

	@include mq("md") {
		padding-bottom: var(--spacing--2xl);
	}
}

.TopTasks_shortcuts {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--md);
}

.TopTasks_shortcutsLink {
	@include button;
	color: var(--color-primary--dark);
	border-color: var(--color-primary);
	background-color: transparent;

	&:hover,
	&:focus {
		color: var(--color-light);
		background-color: var(--color-primary);
	}
}
