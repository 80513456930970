@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.AccordionItem_triggerContainer {
	// Button reset
	border: none;
	color: none;
	background: none;

	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: var(--border--sm) solid var(--color-gray);
	width: 100%;
	padding: var(--spacing--lg) 0;
	cursor: pointer;

	@include mq("md") {
		padding: var(--spacing--lg);
	}
}

.AccordionItem_title {
	font-weight: var(--font-weight--medium);
	text-align: left;
	max-width: 95ch;
	// max-width: 75ch; // If the accordion title need to be max 90 characters long
}

.AccordionItem_iconContainer {
	display: grid;
	align-items: center;
}

.AccordionItem_icon {
	@include transition {
		transition: all var(--trans-time--long);
	}

	&___isOpen {
		transform: rotate(180deg);
	}
}

.AccordionItem_panel {
	text-align: left;
	padding: var(--spacing--lg) 0 var(--spacing--xl);

	@include mq("md") {
		padding: var(--spacing--lg) var(--spacing--lg) var(--spacing--xl);
	}
	ul, ol {
		padding-left: var(--spacing--lg);
	}
}
