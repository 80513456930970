@import "ui/styles/2-tools/tools.media-query.scss";

.Header {
	//position: sticky;
	width: 100%;
	font-size: var(--font-size--sm);
	color: var(--color-light);
	z-index: var(--z-index--header);
	padding: 2.2rem 0 0 0;
	background-color: var(--theme-30);
	// will-change: transform, background-color, opacity, margin;
	//overflow-x: clip;
	//height: 150px;


	@include mq("header") {
		padding: 4rem 0 0 0;
	}

	&___hasSubsiteBar {
		@include mq("header") {
			padding: 0rem;
		}
	}

	&___isFrontPage {
		position: absolute;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
		padding: 0;
	}
}

.Header_inner {
	&___isFrontPage {
		width: 100%;
		padding: 2.2rem 0;

		@include mq("header") {
			padding: 4rem 0;
		}
	}

	&___hasSubsiteBar {
		@include mq("header") {
			padding: 2.2rem 0;
		}
	}
}

@keyframes toggleSearchDown {
	0%    { transform: translateY(0); }
	100%  { transform: translateY(370px);}
}

@keyframes toggleSearchUp {
	0%    { transform: translateY(370px); }
	100%  { transform: translateY(0);}
}

.Header_searchFieldContainer {
	position: absolute;
	padding: 4rem 2.6rem;
	top: -371px;
	left: 0;
	height: 370px;
	width: 100%;
	background-color: var(--color-white);
	z-index: 2;
	transition: 0.2s ease-in-out;

	&___isActive {
		transform: translateY(370px);
		animation: toggleSearchDown .5s
	}

	&___isClosing {
		transform: translateY(0);
		animation: toggleSearchUp .5s
	}
}



.Header_identity {
	// Font size controls the icons size
	display: flex;
	padding-inline-end: var(--spacing--sm);

	@include mq("header") {
		padding-inline-end: var(--spacing--lg);
	}
}

.Header_logo {
	position: relative;

	img {
		position: absolute;
		top: -1.5rem;
		right: -.5rem;
	}


	@include mq("header") {
		img {
			top: -2.5rem;
		}
	}
}

.Header_navigation {
	text-align: right;
	flex: 1;
}

.Header_nav {
	display: none;
	position: absolute;
	width: 100%;
	height: calc(100vh - #{100px});
	top: 93px;
	left: 0;
	margin: auto;
	padding: 2em;
	background-color: var(--color-primary);

	@include mq("header") {
		padding: 0;
	}

	@include mq("header") {
		background-color: transparent;
		height: auto;
		display: block;
		position: static;
	}
}

.Header_navToggle {
	@include mq("header") {
		display: none;
	}
}

.Header_navController {
	display: none;
}

.Header_options {
	display: flex;
	padding-inline-start: var(--spacing--sm);

	@include mq("header") {
		padding-inline-start: var(--spacing--lg);
	}
}

.Header_search {
	cursor: pointer;
	height: 48px;

	@include mq("header") {
		width: 48px;
		height: 48px;
	}
}

.Header_searchIcon {
	display: none;
	@include mq("header") {
		display: block;
		margin: 0;
		padding: 0;
		background: transparent;
		line-height: 0;
		border: none;
	}
}

.Header_language {
	display: none;
	@include mq("header") {
		display: block;
	}
}
