@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ImageWithTitle{
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    background: var(--color-black);
    overflow: hidden;
    aspect-ratio: 1/1;

    @include mq('md'){
        &__2{
            width: var(--image-grid-width-m);
            aspect-ratio: 3/2;
        }

        &__4{
            width: var(--image-grid-width-m);
        }
    }

    @include mq('base'){
        &__2{
            width: var(--image-grid-width-m);
        }

        &__4{
            width: var(--image-grid-width-s);
        }
    }
}

.ImageWithTitle:hover{
    .Image{
        transform: scale(1.0);
        opacity: 0.2;
    }
}

.Image {
    position: relative;
    max-width: 100%;
    transform: scale(1.12);
    transition: opacity var(--trans-time--standard), var(--trans-time--standard);
    overflow: clip;
    opacity: 0.4;


}

.Link{
    position: absolute;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Title{
    position: absolute;
    flex-direction: column;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.Heading{
    color: var(--color-white);
    hyphens: manual;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.Intro{
    color: var(--color-white);
}

