@import "ui/styles/2-tools/tools.media-query.scss";

.ListCard {
	background-color: var(--color-gray--lighter);
	padding: var(--spacing--2xl) var(--spacing--lg);
	min-height: 43.5rem;
	margin: var(--spacing--sm) 0;
	display: flex;

	@include mq("lg") {
		margin: var(--spacing--md) 0;
		padding: var(--spacing--xl);
	}
}

.ListCard_content {
	width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 375px;
    justify-content: space-between;
}

.ListCard_heading {
	padding-bottom: var(--spacing--lg);
	min-height: 8.4rem;
}

.ListCard_listInfo {
	display: flex;
	border-bottom: var(--border--sm) solid var(--color-gray);
	padding: var(--spacing--sm) 0;
	align-items: center;
	min-height: 5.1rem;

	&___lastChild {
		border-bottom: none;
		padding-bottom: var(--spacing--xl);

		p {
			font-weight: var(--font-weight--bold);
		}
	}

	gap: var(--spacing--lg);
	@include mq("md") {
		gap: 0;
	}
}

.ListCard_label {
	min-width: 25%;
	@include mq("md") {
		min-width: 23%;
	}
}

.ListCard_field {
	max-width: 75%;
	word-wrap: break-word;
    overflow-x: hidden;
	
	@include mq("md") {
		max-width: 77%;
	}
}

.ListCard_latestDate {
	padding: var(--spacing--lg) 0 var(--spacing--xl);
}
