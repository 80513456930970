.SubNavigation_wrapper {
	transition-property: transform;
	transition-timing-function: ease;
}

.Navigation_toolsDrawer {
	display: flex;
	justify-content: space-between;
	padding: 4rem var(--spacing--lg) 2rem;
}
