@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action.scss";

.Footer {
	font-size: var(--font-size--md);
	padding: 4.8rem 0 0;
	//line-height: var(--line-height--2xl);
	background-color: var(--color-white);
	border-top: .3rem solid var(--color-primary);

	@include mq("md") {
		padding: 12rem 0 0;
	}

	@media print {
		display: none !important;
	}
}

.Footer_heading {
	margin-bottom: 1.6rem;
}

.Footer_serviceNavContainer {
	display: none;

	@include mq("md") {
		display: block;
	}
}

.Footer_list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.Footer_listLink {
	color: var(--color-dark);
	text-decoration: none;
	margin-bottom: 0.8rem;
	display: inline-block;
	padding-right: 2.9rem;
	line-height: 1.3;

	&:hover {
		text-decoration: underline;
		.Footer_listLinkIcon {
			opacity: 1;
			transform: translateX(0.4rem);
		}
	}
}

.Footer_listLinkIcon {
	position: relative;
	top: 0.7rem;
	left: 0.3rem;
	opacity: 0;
	transition: transform ease-in 0.4s, opacity ease-in 0.2s;
	stroke: var(--color-primary);
	stroke-width: 1.5;
}

.Footer_CustomLink {
	display: inline-block;
	margin-top: 5rem;
	color: var(--color-primary);
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.Footer_richtextContainer {
	order: 2;

	@include mq("md") {
		order: initial;
	}
}

.Footer_RichText {
	h2 {
		margin-bottom: 1.6rem;
		@include paragraph--xl;
	}
	p {
		margin-bottom: 3.2rem;
		@include paragraph--md;
		color: var(--color-dark);
	}

	a {
		color: var(--color-primary);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.Footer_logoContainer {
	display: flex;
	order: 1;
	@include mq("md") {
		order: initial;
	}

	@include mq("md", "max") {
		display: block;

		a {
			width: 100%;
			height: 100%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 4rem;
		}

		padding-bottom: 4rem;
	}
}

.Footer_logo {
	margin: 0 auto;
}

.Footer_metaContainer {
	border-top: 0.1rem solid var(--color-border--gray);
	margin-top: 2.8rem;
	padding: 2.4rem 0 5rem;

	@include mq("md") {
		margin-top: 6.8rem;
	}
}

.Footer_metaList {
	list-style: none;
	padding: 0;
	margin: 0;

	@include mq("md") {
		display: flex;
		justify-content: center;
	}
}

.Footer_metaList li {
	margin: 1.4rem 0;

	&:hover {
		text-decoration: underline;
	}

	@include mq("md") {
		margin: 0 2.8rem;
	}
}

.Footer_metaListLink {
	color: var(--color-dark);
}
