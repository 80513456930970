@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldTextarea_wrapper {
	border-radius: 2.8rem;
	width: 100%;
	border: 0.15rem solid var(--color-border--gray);
	height: 18rem;
	display: flex;
	align-items: flex-start;

	position: relative;
	flex-flow: column;
	color: var(--color-gradient--80);

	&.hasError {
		border-color: var(--color-error);
	}

	&.isDisabled {
		background-color: var(--color-gray--light);
		cursor: not-allowed;
	}
}

.FormfieldTextarea_ErrorMessage {
	&.hasError {
		color: var(--color-error);
	}
}

.FormfieldTextarea_input {
	@include input;
	width: 94%;
	height: 98%;
	margin: 0;
	resize: none;

	@include mq("md") {
		width: 98%;
	}

	.isDisabled & {
		cursor: not-allowed;
	}
}

.FormfieldTextarea_label {
	@include formfield-label;
}

.FormfieldTextarea_wrapper:not([disabled]):hover,
.FormfieldTextarea_label:hover ~ .FormfieldTextarea_wrapper:not([disabled]),
.FormfieldTextarea_wrapper:focus {
	border-color: var(--color-primary);
}
