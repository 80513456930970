@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.BlurbCard {
	padding: var(--spacing--lg) 0;
}

.BlurbCard_heading {
	font-weight: var(--font-weight--semibold);
	padding-bottom: var(--spacing--xl);

	@include mq("lg") {
		font-weight: var(--font-weight--bold);
	}
}

.BlurbCard_kicker {
	padding-bottom: var(--spacing--base);
	font-weight: var(--font-weight--bold);
}

.BlurbCard_text {
	padding-bottom: var(--spacing--lg);
}

.BlurbCard_link {
	@include paragraph--md;
}
