/* ------------------------------------ *\
		tools.action
\* ------------------------------------ */

@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

// Action
@mixin action {
	padding: 0;
	background: transparent;
	border-color: transparent;
	cursor: pointer;
}

// Button
@mixin button {
	@include action;
	@include paragraph--md;
	font-weight: var(--font-weight--medium);
	display: inline-block;
	padding: var(--spacing--base) var(--spacing--xl);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--lg);
	border: var(--border--md) solid var(--color-white);
	text-align: center;

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___primary {
		color: var(--color-light);
		background: var(--color-primary);
		border: var(--border--md) solid var(--color-primary);

		&:hover,
		&:focus {
			color: var(--color-primary);
			background-color: var(--color-white);
		}
	}

	&___primarySmall {
		padding: var(--spacing--xs) var(--spacing--sm);
		color: var(--color-light);
		background: var(--color-primary);
		border: var(--border--md) solid var(--color-primary);

		&:hover,
		&:focus {
			color: var(--color-primary);
			background-color: var(--color-white);
		}
	}

	&___secondary {
		color: var(--color-primary--dark);
		border-color: var(--color-primary);
		background-color: transparent;
	}

	&___secondarySmall {
		padding: var(--spacing--xs) var(--spacing--base);
		color: var(--color-primary);
		border-color: var(--color-primary);
		background-color: transparent;

		&:hover,
		&:focus {
			color: var(--color-light);
			background-color: var(--color-primary);
		}
	}

	&___secondary,
	&___secondarySmall {
		display: flex;
		gap: var(--spacing--xs);
		align-items: center;
		justify-content: center;

		svg {
			path {
				stroke: var(--color-concern);
			}
		}

		&:hover,
		&:focus {
			color: var(--color-white);
			background-color: var(--color-primary);

			svg {
				path {
					stroke: var(--color-white);
				}
			}
		}
	}

	&___inactive,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}
}

@mixin pseudo-hover {
	text-shadow: 0.1rem 0 0 currentColor;
}

@mixin link-hover-animation($color: var(--color-dark)) {
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 0.1rem;
		background: $color;
		position: relative;
		top: -0.3rem;
		@include transition {
			transition: width var(--trans-time--shorter);
		}
	}

	&:hover::after {
		width: 0;
	}
}

@mixin download-link-richtext() {
	@include action;
	@include paragraph--md;
	font-weight: var(--font-weight--medium);
	padding: var(--spacing--base) var(--spacing--xl);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--lg);
	border: var(--border--md) solid var(--color-white);
	color: var(--color-primary);
	border-color: var(--color-primary);
	display: inline-flex;
	gap: var(--spacing--sm);
	align-items: center;
	min-width: 180px;
	justify-content: center;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0zLjQwMjM0IDE3LjQxMzNMMy40MDIzNCAyOC44MjYxSDExLjAwMjNIMjAuNTAyOEgyOC4xMDIzVjE3LjQxMzMiIHN0cm9rZT0iIzAwODA5YyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8cGF0aCBkPSJNMTUuNzUxNiAyLjY2MDU4TDE1Ljc1MyAyMS4xODA4TTE1Ljc1MyAyMS4xODA4TDEwLjUyNjYgMTUuMDAwNk0xNS43NTMgMjEuMTgwOEwyMC45NzY2IDE1LjAwMDYiIHN0cm9rZT0iIzAwODA5YyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==");
	background-repeat: no-repeat;
	background-position: center right 2rem;
	padding-right: 6.5rem;

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&:hover,
	&:focus {
		color: var(--color-white);
		background-color: var(--color-primary);
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0zLjQwMjM0IDE3LjQxMzNMMy40MDIzNCAyOC44MjYxSDExLjAwMjNIMjAuNTAyOEgyOC4xMDIzVjE3LjQxMzMiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8cGF0aCBkPSJNMTUuNzUxNiAyLjY2MDU4TDE1Ljc1MyAyMS4xODA4TTE1Ljc1MyAyMS4xODA4TDEwLjUyNjYgMTUuMDAwNk0xNS43NTMgMjEuMTgwOEwyMC45NzY2IDE1LjAwMDYiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==");
	}
}

@mixin external-link {
	&[target="_blank"] {
		background-image: url("data:image/svg+xml,%3Csvg width='19' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.1875 8C8.85938 8 8.625 8.25781 8.625 8.5625V11.1875C8.625 11.3047 8.53125 11.375 8.4375 11.375H1.3125C1.19531 11.375 1.125 11.3047 1.125 11.1875V4.0625C1.125 3.96875 1.19531 3.875 1.3125 3.875H3.9375C4.24219 3.875 4.5 3.64062 4.5 3.3125C4.5 3.00781 4.24219 2.75 3.9375 2.75H1.3125C0.585938 2.75 0 3.35938 0 4.0625V11.1875C0 11.9141 0.585938 12.5 1.3125 12.5H8.4375C9.14062 12.5 9.75 11.9141 9.75 11.1875V8.5625C9.75 8.25781 9.49219 8 9.1875 8ZM11.4375 0.5H7.5C7.17188 0.5 6.9375 0.757812 6.9375 1.0625C6.9375 1.39062 7.17188 1.625 7.5 1.625H10.0781L4.28906 7.41406C4.05469 7.64844 4.05469 8 4.28906 8.21094C4.38281 8.32812 4.52344 8.375 4.6875 8.375C4.82812 8.375 4.96875 8.32812 5.0625 8.21094L10.875 2.42188V5C10.875 5.32812 11.1094 5.5625 11.4375 5.5625C11.7422 5.5625 12 5.32812 12 5V1.0625C12 0.757812 11.7422 0.5 11.4375 0.5Z' fill='%231B1B26'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center right;
		padding-right: 2.5rem;
	}
}

// Input
@mixin input {
	@include paragraph--md;
	outline: none;
	border: 0.15rem solid transparent;
	padding: var(--spacing--base) var(--spacing--lg);
	margin-bottom: var(--spacing--2xl);
	border-radius: 4.8rem;

	&.hasError {
		border-color: var(--color-error);
		color: var(--color-error);
	}

	/* &::placeholder {
		color: red;
	} */

	// Used for text, email, password, number etc.
	&___string {
		width: 100%;
		background: var(--color-gradient--5);
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}

	&___string:focus,
	&___active {
		border-color: currentColor;
	}

	// Used for checkbox and radio
	&___choice {
		height: 1.5em;
		width: 1.5em;
	}

	// Used for textarea
	&___textarea {
		width: 100%;
		border: var(--border--sm) solid currentColor;
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}
}
