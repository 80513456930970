@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormfieldRadio {
	position: relative;
	display: flex;
	flex-flow: column;
	color: var(--color-gradient--80);

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.FormfieldRadio_label {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;

	.isDisabled & {
		cursor: not-allowed;
	}

	&:before {
		content: "";
		display: block;
		width: 3rem;
		height: 3rem;
		background-color: var(--color-gradient--5);
		border-radius: 100%;
		margin-right: var(--spacing--xs);
		border: var(--border--sm) solid var(--color-border--gray);

		.hasError & {
			border-color: var(--color-error);
		}
	}

	&:after {
		content: "";
		display: block;
		width: 3rem * 0.45;
		height: 3rem * 0.45;
		background-color: transparent;
		border-radius: 100%;
		position: absolute;
		left: 3rem/2;
		transform: translateX(60%);
	}

	.FormfieldRadio_input:checked ~ &:after {
		background-color: var(--color-primary);
	}

	.FormfieldRadio___circle & {
		&:before {
			transition: background-color 0.2s ease-in-out;
			width: 4rem;
			height: 4rem;
		}
	}
}

.FormfieldRadio_input {
	@include input;
	width: 3rem;
	height: 3rem;
	border-radius: 100%;
	margin: var(--spacing--2xs) 0;
	position: absolute;
	opacity: 0;
	.FormfieldRadio___circle & {
		&:checked ~ label:after {
			background-color: transparent;
		}
		&:checked ~ label:before {
			background-color: var(--color-primary);
			border-color: transparent;
		}
		&:checked ~ label p {
			color: var(--color-white);
		}
	}
}

.FormfieldRadio_input:focus ~ .FormfieldRadio_label:before,
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:hover:before {
	border-color: var(--color-primary);
}

.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:hover p,
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:focus p {
	.FormfieldRadio___circle & {
		color: var(--color-white);
	}
}
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:hover:before,
.FormfieldRadio_input:not([disabled]) ~ .FormfieldRadio_label:focus:before {
	.FormfieldRadio___circle & {
		border-color: transparent;
		background: var(--color-primary);
		color: var(--color-white);
	}
}

.FormfieldRadio___circle p {
	display: block;
	width: auto;
	height: 1.35rem;
	background-color: transparent;
	border-radius: 100%;
	position: absolute;
	top: 1.4rem;
	left: 2rem;
	transform: translateX(-50%);
}

// Rating buttons
.FormfieldRadio___star {
	input:focus ~ label:before,
	input:not([disabled]) ~ label:hover:before {
		border-color: transparent !important;
	}
	&:not(:checked) > label {
		float: right;
		width: 25px;
		padding-right: 5px;
		overflow: hidden;
		white-space: nowrap;
		cursor: pointer;
		font-size: 0;
		line-height: 25px;
		color: var(--color-gradient--5);
		transition: all 0.2s ease-in-out;
	}
	&:not(:checked) > label:before {
		content: "★";
		float: left;
		font-size: 30px;
		background-color: transparent;
	}
	& > input:checked ~ label {
		color: var(--color-primary);
	}

	&:not(:checked) > label:hover,
	&:not(:checked) > label:hover ~ label,
	&:not(:checked) > label:focus,
	&:not(:checked) > label:focus ~ label {
		color: var(--color-primary);
	}

	& > input:checked + label:hover,
	& > input:checked + label:hover ~ label,
	& > input:checked ~ label:hover,
	& > input:checked ~ label:hover ~ label,
	& > label:hover ~ input:checked ~ label,
	& > input:checked + label:focus,
	& > input:checked + label:focus ~ label,
	& > input:checked ~ label:focus,
	& > input:checked ~ label:focus ~ label,
	& > label:focus ~ input:checked ~ label {
		color: var(--color-primary);
	}
	& > label:active {
		position: relative;
	}
}
