@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.NavigationLeft {
	width: 33rem;
	display: none;
	@include mq("base") {
		display: block;
	}
}

.NavigationLeft_heading {
	padding-bottom: var(--spacing--xl);
}


.NavigationItem_item {
	text-decoration: none;
	list-style-type: none;

	ul {
		margin-left: var(--spacing--md);
	}
}

.NavigationItem_dropdown {
	display: flex;
	gap: var(--spacing--xs);
	padding: var(--spacing--xs) var(--spacing--sm) var(--spacing--xs) 0;
	border-bottom: 0.1rem solid rgba(148, 148, 143, 0.2);
}

.NavigationItem_buttonIconContainer {
	min-width: 1.6rem;
	cursor: pointer;
}

.NavigationItem_buttonIconContainerInner {
	width: 1.6rem;
	height: 100%;
	display: grid;
	align-items: center;
}

.NavigationItem_buttonIcon {
	width: 100%;
	height: 100%;
	transform: rotate(-180deg);

	&___isActive {
		transform: rotate(-90deg);
	}

	@include transition {
		transition: all var(--trans-time--long);
	}
}

.NavigationItem_link {
	@include paragraph--md;
	&___isActive {
		font-weight: var(--font-weight--medium);
	}
}

.NavigationItem_link___isCurrent {
	font-weight: bold;
	text-decoration: underline;
}
