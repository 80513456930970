@import "ui/styles/2-tools/tools.action";

.VideoList {
	width: 100%;
	margin: 0 auto var(--spacing--5xl) auto;
	max-width: var(--container-width--standard);

	&___margin {
		margin-top: var(--spacing--5xl);
		padding: 0 var(--spacing--base);
	}
}

.VideoList_fullScreen {
	iframe {
		position: fixed !important; // We can do this because the iframe has inline styles
		z-index: 1000;
	}
}

.VideoList_heading {
	margin-bottom: var(--spacing--2xl);
}

.VideoList_media {
	margin-bottom: var(--spacing--base);
}

.VideoList_controls {
	margin: var(--spacing--xl) 0 var(--spacing--xl) 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column-reverse;

	@include mq("md") {
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.VideoList_controlButtons {
	display: flex;
	gap: var(--spacing--base);
	justify-content: center;

	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}

	&___arrow {
		margin-bottom: var(--spacing--lg);
		@include mq("md") {
			margin-bottom: 0;
		}
	}
}

.VideoList_exitVideoFullScreenButton {
	position: fixed;
	top: 2%;
	right: 5%;
	z-index: 1001;
	background: var(--color-white);
}

.VideoList_navigation {
	@include action;
	height: 4.8rem;
	width: 4.8rem;
	margin-bottom: var(--spacing--2xs);

	span {
		height: 4.8rem;
		width: 4.8rem;
	}
}

.VideoList_description {
	margin-bottom: var(--spacing--4xl);
}

.VideoList_list {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--spacing--xl);

	@include mq("xs") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mq("sm") {
		grid-template-columns: repeat(3, 1fr);
	}
}

.VideoList_listMediaWrapper {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		z-index: var(--z-index--content);
		cursor: pointer;
		transition: background-color 0.2s ease-in;
	}
	&:hover:before {
		background-color: var(--color-dark);
		box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.35);
	}
}

.VideoList_listMedia {
	margin-bottom: var(--spacing--sm);
}

.VideoList_listMedia___Active {
	box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.15);
}
