@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.LanguageSelector {
	@include paragraph--sm;
	cursor: pointer;
	position: relative;

	@include mq("md") {
		margin-inline-start: 2rem;
		margin-block-start: 1rem;
	}

	&___isFrontPage {
		color: var(--color-white);
	}
}

.LanguageSelector_select {
	border: none;
	outline: none;
	appearance: none;
}

.LanguageSelector_wrapper {
	@include reset-list;
	display: none;
	padding-top: var(--spacing--sm);

	@include mq("md", "max") {
		width: fit-content;
		text-align: end;
		margin-left: -2.2rem;
	}

	&.isOpen {
		display: flex;
		flex-direction: column;
		z-index: 1;
	}
}

.LanguageSelector_link {
	color: var(--color-black);

	&___isFrontPage {
		color: var(--color-white);
	}

	&:hover {
		text-decoration: underline;
	}
}

.LanguageSelector_button {
	&___isFrontPage {
		color: var(--color-white);
	}
	&___isActive {
		color: var(--color-primary);
	}
	&___is {
		display: none;
	}
}

.LanguageSelector_toggle {
	position: relative;
	display: flex;
	gap: var(--spacing--sm);

	@include mq("md", "max") {
		width: fit-content;
	}
}

.LanguageSelector_iconContainer {
	margin-left: calc(-1 * var(--spacing--sm));
}
.LanguageSelector_icon {
	transform: rotate(-180deg);
	margin-bottom: 0.3rem;
	@include transition {
		transition: all var(--trans-time--long);
	}
	.isOpen & {
		transform: rotate(0deg);
	}
	&___isFrontPage {
		path {
			stroke: var(--color-white);
		}
	}
}
