@import "ui/styles/2-tools/tools.media-query.scss";

.LinkboxAutomatic_container {
	@include mq("md", "max") {
		padding: 0 !important;
	}
}

.LinkboxAutomatic {
	margin-bottom: var(--spacing--module-bottom);
	padding: var(--spacing--4xl) var(--spacing--3xl);

	@include mq("md") {
		padding: var(--spacing--3xl);
	}

	@include mq("lg") {
		padding: var(--spacing--4xl);
		margin-bottom: var(--spacing--4xl);
	}
}

.LinkboxAutomatic_heading {
	padding-bottom: var(--spacing--md);
}

.LinkboxAutomatic_linksContainer {
	@include mq("md") {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: var(--spacing--lg);
	}
}

.LinkboxAutomatic_links {
	list-style-type: none;
	border-bottom: 1px solid var(--color-gray);
}

.LinkboxAutomatic_link {
	display: flex;
	justify-content: space-between;
	padding: var(--spacing--lg) 0;
	gap: var(--spacing--sm);
	text-decoration: none;
	color: var(--color-black);
	transition: var(--trans-time--long);

	&:hover {
		transform: translateX(0.4rem);
	}
}

.LinkboxAutomatic_iconWrapper {
	display: grid;
	align-items: center;

	@include mq("md") {
		padding-right: var(--spacing--sm);
	}
}

.LinkboxAutomatic_icon {
	stroke: var(--color-primary);
	stroke-width: 1.5;
}
