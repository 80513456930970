@import "ui/styles/2-tools/tools.action.scss";
.ArticleText {
	/**
	* In this molecule we style html elements directly
	* since they come from the RTE of a CMS or another editor
	*/
	max-width: var(--container-width--article-text);
	font-size: var(--font-size--md);

	:global(.u-with-leftnavigation-container) & {
		margin: 0;
	}

	p {
		margin-bottom: var(--spacing--md);

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	blockquote {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--xl);
		margin-left: auto;
		margin-right: auto;
		font-size: var(--font-size--xl);
		font-weight: var(--font-weight--bold);
		line-height: var(--line-height--3xl);
		text-align: center;
		color: var(--color-primary);
		width: 75%;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 3em;
			height: 0;
			margin: 0.5em auto 0.5em auto;
			border-bottom: 1px solid currentColor;
		}
	}

	ul,
	ol {
		margin: var(--spacing--base) 0;
		padding-left: 1em;
		line-height: var(--line-height--2xl);
	}

	a {
		color: var(--color-concern);
		@include external-link;
	}
}
