.ArticleDate {
	margin-bottom: 3em;
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	line-height: var(--line-height--4xl);
	font-weight: var(--font-weight--normal);
}

.Container {
	display: flex;
	flex-direction: row-reverse;
	border-top: .1rem solid var(--color-border--gray);
	padding-top: var(--spacing--xs);
	margin:var(--spacing--xl) 0 var(--spacing--lg);
}

.Container > * {
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--normal);
}

.ArticleDate__Text {
	&:first-letter {
		text-transform: uppercase;
	}
}

.ArticleDate__Text::after {
	border-right: thin solid;
	content: "";
	margin: -.125rem .625rem;
	min-height: .75rem;
}

.ArticleOld {
	border: 1px solid;
	padding-left: .5rem;
	padding-right: .5rem;
}