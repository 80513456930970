@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.UrgentContactItem {
	padding-bottom: var(--spacing--2xl);

	@include mq("md") {
		padding-bottom: var(--spacing--lg);
	}
}

.UrgentContactItem_heading {
	color: inherit;
	text-decoration: none;
	@include mq("md") {
		font-size: var(--font-size--9xl);
		line-height: var(--line-height--6xl);
	}
}

.UrgentContactItem_text {
	padding-top: var(--spacing--xs);
	display: flex;
	gap: var(--spacing--sm);
}
