@import "ui/styles/2-tools/tools.media-query.scss";

.ImageSlider_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--container-width--article-text);
    margin-bottom: var(--spacing--md)
}

.ImageSlider_desktop{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include mq("md"){
        padding-bottom: calc(var(--grid-gutter) * 2);
        padding-top: calc(var(--grid-gutter) * 2);
    }
}

.ImageSlider_buttonsMobile{
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing--sm);
}

.ImageSlider_prevButtonDesktop,
.ImageSlider_nextButtonDesktop {
    display: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
    flex: 0 0 1vw;

    @include mq("md") {
		display: flex;
	}
}

.ImageSlider_prevButtonMobile,
.ImageSlider_nextButtonMobile{
    display: flex;
    cursor: pointer;
    transition: opacity 0.3s ease;
    flex: 0 0 1vw;

    @include mq("md") {
		display: none;
	}
}

.ImageSlider_spacer{
    display: flex;
    flex-grow: 1;
}

.ImageSlider_nextButtonDesktop{
    justify-content: flex-end;
    width: 48px;
    margin-left: 30px;
}

.ImageSlider_prevButtonDesktop{
    justify-content: flex-start;
    width: 48px;
    margin-right: 30px;
}

.ImageSlider_image {
    transition: opacity 0.3s ease;
}

.image {
	width: 100%;

    @include mq("md"){
	    margin-bottom: var(--spacing--xs);
    }
}

.ImageSlider_imageDescription_desktop {
    display: none;
    @include mq("md"){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-style: italic;
        font-size: var(--font-size--sm);
        margin-top: var(--spacing--2xs);
        height: 90px;
    }
}

.ImageSlider_mobile_background{
    background-color: var(--theme-20);
    padding: 1% 5%;

    @include mq("md"){
        display: none;
    }
}

.ImageSlider_imageDescription_mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-style: italic;
    font-size: var(--font-size--sm);
    margin-top: var(--spacing--xs);
    
    height: 180px;

    @include mq("xs"){
        height: 120px;
    }

    @include mq("sm"){
        height: 100px;
    }

    @include mq("md"){
        display: none;
    }
}

.caption{
    text-align: left;
}