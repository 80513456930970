@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
.NewsTeaser {
	padding: var(--spacing--3xl) 0;

	@include mq("lg") {
		padding: calc(3 * var(--spacing--2xl)) 0;
	}
}
.NewsTeaser_header {
	padding-bottom: var(--spacing--2xl);
	@include mq("md") {
		padding-bottom: var(--spacing--5xl);
		display: flex;
		justify-content: space-between;
	}
}
.NewsTeaser_headingContainer,
.NewsTeaser_buttonContainer {
	display: grid;
	align-items: center;
}

.NewsTeaser_heading {
	@include mq("md") {
		font-size: var(--font-size--9xl);
	}
}

.NewsTeaser_button {
	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}
	&___mobile {
		width: 100%;
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}
.NewsTeaser_listCards {
	padding-bottom: var(--spacing--xl);
	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}
	&___mobile {
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}
