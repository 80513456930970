.SubNavigationItem {
	width: 100%;
	top: .1rem;
	transition: all var(--transition-time--md) ease;
	border-top: thin solid var(--color-border--light);

	&___isMobile {
		padding-bottom: var(--spacing--xxxxl);
		transition: none;
	}
}

// Item

.SubNavigationItem_item {
	display: flex;
    min-height: 7rem;
	justify-content: space-between;
    border-bottom: thin solid var(--color-border--light);
	border-left: .9rem solid;

	&.isActive {
		background-color: var(--color-gray--lighter);
	}

	&:first-child {
		border-top: thin solid var(--color-border--light);
	}
}

// Link

.SubNavigationItem_link,
.SubNavigationItem_text,
.SubNavigationItem_textBack {
	display: flex;
    align-items: center;
    position: relative;
    padding: 2rem;
	color: var(--color-dark);
	font-size: var(--font-size--4xl);
    text-decoration: none;
    flex: 1;

	.hasChildren & {
		padding-right: 0;
	}
}

.SubNavigationItem_textBack {
	padding-left: 0;
}

.SubNavigationItem_text {
	border-right: thin solid var(--color-border--light);
	overflow: hidden;
	text-overflow: ellipsis;
}

.SubNavigationItem_link {
	&:hover,
	&:focus {
		background-color: var(--color-gray--lighter);
	}
}

.SubNavigationItem_topLink {
	display: flex;
	align-items: center;
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	margin-right: auto;
}

.SubNavigationItem_topLinkText {
	flex: 1;
	display: block;
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: var(--spacing--xs);
}

// Button
.SubNavigationItem_prev {
	width: 7rem;
	height: 7rem;
	background-color: transparent;
	border: 0;
	color: var(--color-dark);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	flex: 0 0 var(--sizes--sub-nav-item);
}

.SubNavigationItem_next {
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: transparent;
	border: 0;
	color: var(--color-black);
	align-items: center;
	padding: 0;

	&:hover,
	&:focus {
		background-color: var(--color-gray--lighter);
	}
}

.SubNavigationItem_nextButton {
	background-color: transparent;
	border: none;
	margin: 0;
	padding: 0;
}

// Icon
.SubNavigationItem_nextIcon,
.SubNavigationItem_prevIcon {
	background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    cursor: pointer;
    width: 7rem;
    height: 7rem;
    padding: 2rem;
	stroke: var(--color-primary);
	stroke-width: 1.5;
	display: flex;
	align-items: center;

	.SubNavigationItem_topLink & {
		margin: 0 var(--spacing--lg) 0 0;
	}
}

.SubNavigationItem_prevIcon {
	transform: rotate(180deg);
	border-left: none;
	&:hover,
	&:focus {
		background-color: var(--color-gray--lighter);
	}
}
