@import "ui/styles/2-tools/tools.action.scss";

/* CenteredText styles */
.CenteredText_container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
	padding-bottom: var(--spacing--md);

	@include mq("md"){
		padding: var(--spacing--sm) var(--spacing--12xl) var(--spacing--md) var(--spacing--12xl);
	}
}

.CenteredText {
	display: flex;
	flex-direction: column;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.005em;
	text-align: center;
	margin: 0 auto;

	h1, h2, h3, h4, h5, h6{
		text-align: center !important;
	}

	p {
		margin-bottom: var(--spacing--sm);
		text-align: center !important;

		&:last-of-type {
			margin-bottom: 0px;
			text-align: center;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}
	
	blockquote {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--xl);
		margin-left: auto;
		margin-right: auto;
		font-size: var(--font-size--xl);
		font-weight: var(--font-weight--bold);
		line-height: var(--line-height--3xl);
		text-align: center;
		color: var(--color-primary);
		width: 75%;
	
		&:before,
		&:after {
			content: "";
			display: block;
			width: 3em;
			height: 0;
			margin: 0.5em auto 0.5em auto;
			border-bottom: 1px solid currentColor;
		}
	}

	ul,
	ol {
		margin: var(--spacing--base) 0;
		padding-left: 1em;
		line-height: var(--line-height--2xl);
	}

	a {
		color: var(--color-concern);
		@include external-link;
	}
}