@import "ui/styles/2-tools/tools.media-query.scss";

.gridish{
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
}

.gridish > * {
    flex: 1 1 20ch;
}

.pictureFirst{
    flex-direction: column;

    @include mq('md'){
        flex-direction: row;
    }
}

.pictureLast{
    flex-direction: column;

    @include mq('md'){
        flex-direction: row-reverse;
    }
}

.textbox{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: var(--theme-30);
}

.padding{
    padding: 15%;
}

.button{
    min-width: 8ch;
    display: inline-block;
    margin-top: var(--spacing--sm);
}

.paragraph{
    padding-top: var(--spacing--xs);
    padding-bottom: var(--spacing--xs);
}