@import "ui/styles/2-tools/tools.media-query.scss";

.Main {
	position: relative;
}

.Main_withNavigationLeftContainer {
	display: flex;
	gap: var(--spacing--3xl);
}

.Main_article {
	@include mq("md") {
		padding-top: var(--spacing--xl);
	}
}
