@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.Underside {
	@include mq("md", "max") {
		padding: var(--spacing--lg) var(--spacing--lg) 0;
	}

	&___noMedia {
		padding: var(--spacing--lg) 0;
	}
}

.UndersideCard_mediaContainer {
	padding-bottom: var(--spacing--xl);

	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}
}

.Underside_media {
	flex: 1;
	z-index: 2;
	width: 100%;
	height: 100%;
	order: 2;

	@include mq("md") {
		order: 1;
	}

	@include mq("lg") {
		width: 740px;
	}
}

.Underside_text {
	flex: 1;
	padding: var(--spacing--4xl) var(--spacing--xs) 0 0;

	@include mq("md") {
		padding: var(--spacing--4xl) var(--spacing--md) 0 var(--spacing--md);
		&___hasMedia {
			max-width: 35%;
		}
	}

	@include mq("lg") {
		padding: 12rem var(--spacing--md) 0 10rem;
	}

	@include mq("xl") {
		padding: 12rem var(--spacing--md) 0 21rem;
		&___hasMedia {
			max-width: 50%;
		}
	}

	&___noMedia {
		padding: var(--spacing--4xl) 0 0 0;
		@include mq("md") {
			padding: var(--spacing--4xl) 0 0 0;
		}

		@include mq("lg") {
			padding: 12rem 0 0 0;
		}

		@include mq("xl") {
			padding: 12rem 0 0 0;
		}
	}
}

.Underside_heading {
	padding-bottom: var(--spacing--2xl);
	color: var(--color-primary--dark);
}

.Underside_links {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--md);
	padding: 0 var(--spacing--xs) var(--spacing--4xl) 0;

	@include mq("md") {
		padding: 0 var(--spacing--md) var(--spacing--4xl);
	}

	@include mq("lg") {
		padding: 0 10rem 12rem;
	}

	@include mq("xl") {
		padding: 0 21rem 12rem;
	}

	&___noMedia {
		padding: 0 0 var(--spacing--4xl) 0;
		@include mq("md") {
			padding: 0 0 var(--spacing--md) 0;
		}

		@include mq("lg") {
			padding: 0 0 10rem 0;
		}

		@include mq("xl") {
			padding: 0 0 21rem 0;
		}
	}
}

.Underside_link {
	color: var(color-concern--dark);
}
