@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.action";

.VideoTeaser {
	padding: var(--spacing--6xl) 0;
	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}

	&___mobile {
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}

.VideoTeaser_heading,
.VideoTeaser_description {
	padding: 0 var(--spacing--lg) var(--spacing--xl) var(--spacing--lg);

	@include mq("md") {
		padding-left: var(--grid-gutter);
		padding-bottom: var(--spacing--2xl);
	}
}

.VideoTeaser_heading {
	text-decoration: none;
	color: var(--color-concern);
	
	&___link{
		text-decoration: underline;
		a {
			color: var(--color-dark);
		}
	}
}

.VideoTeaser_videoCards {
	margin-bottom: var(--spacing--xl);

	&___desktop {
		display: none;
		position: relative;
	}

	&___mobile {
		display: block;
	}

	@include mq("md") {
		&___desktop {
			display: block;
		}

		&___mobile {
			display: none;
		}
	}
}

.VideoTeaser_videoCard {
	position: relative;
	padding: 0 calc(var(--grid-gutter));

	&___mobile {
		padding-bottom: calc(var(--grid-gutter) * 10);
	}
}

.VideoTeaser_mediaBox {
	height: 176px;
}

.VideoTeaser_videoCardHeading {
	padding-top: var(--spacing--base);
}

.VideoTeaser_navigation {
	@include action;
	border: none;
	position: absolute;
	z-index: 100;
	top: 40%;
	transform: translate(0, -50%);
	
	
	svg {
		position: relative;
		z-index: 2;
	}

	&::before {
		content: '';
		width: 4.8rem;
		height: 4.8rem;
		background: var(--color-white);
		opacity: .7;
		border-radius : 50%;
		position: absolute;
		left: auto;
		z-index: 1;
		transition: opacity .2s ease-in;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}

	&___prev {
		left: 2rem;
		right: auto;
		@include mq("lg") {
			left: -5rem;
		}
	}

	&___next {
		left: auto;
		right: 2rem;
		
		@include mq("lg") {
			right: -5rem;
		}
	}

	&:global(.swiper-button-disabled) {
		display: none;
	}
}
