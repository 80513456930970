@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.PrioritizedContent {
	background-color: var(--PrioritizedContent-color);

	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
			margin-top: var(--spacing--xl);
		}
	}

	&___mobile {
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}

.PrioritizedContent_headerContainer {
	@include mq("md") {
		display: flex;
		justify-content: space-between;
	}
}

.PrioritizedContent_heading {
	padding: 0 var(--spacing--lg) var(--spacing--xl) var(--spacing--lg);
	color: var(--color-primary);

	@include mq("md") {
		font-size: var(--font-size--9xl);
		line-height: var(--line-height--6xl);
		padding-left: var(--grid-gutter);
		padding-bottom: var(--spacing--4xl);
	}
}

.PrioritizedContent_seeAllLink {
	display: inline-block;
	height: 0%;
	position: relative;
	top: -1rem;
	margin-left: var(--spacing--md);
	margin-bottom: var(--spacing--md);
}

.PrioritizedContent_contentCards {
	padding-bottom: var(--spacing--xl);

	&___desktop {
		display: none;
	}

	&___mobile {
		display: block;
	}

	@include mq("md") {
		&___desktop {
			display: block;
		}
		&___mobile {
			display: none;
		}
	}
}
