/* ------------------------------------ *\
		settings.colors
\* ------------------------------------ */

:root {
	// Neutral colors
	--color-black: #222222;
	--color-gray--dark: #595959;
	--color-gray: #8f8f8f;
	--color-gray--light: #eeeeee;
	--color-gray--lighter: #f6f6f6;
	--color-white: #ffffff;

	//Umbraco theme colors fallback
	--color-base: var(--color-concern);
	--theme-50: var(--color-concern--50);
	--theme-30: var(--color-concern--30);
	--theme-20: var(--color-concern--20);
	--theme-10: var(--color-concern--10);

	// Primary colors
	--color-concern--base: 0, 128, 156; // #00809c
	--color-concern: #00809c;
	--color-concern--50: #80bfcd;
	--color-concern--30: #b3d9e1;
	--color-concern--20: #cce6eb;
	--color-concern--10: #e6f2f5;
	--color-concern--dark: #036174;

	// Secondary colors
	--color-klima--base: 0, 176, 146; // #00b092
	--color-klima: #00b092;
	--color-klima--50: #80d7c8;
	--color-klima--30: #b3e7de;
	--color-klima--20: #ccefe9;
	--color-klima--10: #e6f7f4;

	--color-politik--base: 15, 77, 188; // #0f4dbc;
	--color-politik: #0f4dbc;
	--color-politik--50: #87a6dd;
	--color-politik--30: #b7caeb;
	--color-politik--20: #cfdbf2;
	--color-politik--10: #e7edf8;

	--color-sundhed--base: 146, 91, 209; // #925bd1;
	--color-sundhed: #925bd1;
	--color-sundhed--50: #c8ade8;
	--color-sundhed--30: #decef1;
	--color-sundhed--20: #e9def6;
	--color-sundhed--10: #f4effa;

	--color-socialtilbud--base: 252, 158, 119; // #fc9e77;
	--color-socialtilbud: #fc9e77;
	--color-socialtilbud--50: #fdcebb;
	--color-socialtilbud--30: #fee2d6;
	--color-socialtilbud--20: #feece4;
	--color-socialtilbud--10: #fff5f1;

	--color-regionaludvikling--base: 192, 164, 148; // #c0a494
	--color-regionaludvikling: #c0a494;
	--color-regionaludvikling--50: #dfd1c9;
	--color-regionaludvikling--30: #ece4df;
	--color-regionaludvikling--20: #f2edea;
	--color-regionaludvikling--10: #f9f6f4;

	--color-helbredsprofil--base: 53, 123, 121;
	--color-helbredsprofil: #357b79;
	--color-helbredsprofil--50: #9abdbc;
	--color-helbredsprofil--30: #c2d7d7;
	--color-helbredsprofil--20: #d7e5e4;
	--color-helbredsprofil--10: #ebf2f2;

	--color-helbredsprofil-lime--base: 170, 193, 45; // #aac12d
	--color-helbredsprofil-lime: #aac12d;
	--color-helbredsprofil-lime--50: #d4e096;
	--color-helbredsprofil-lime--30: #e6ecc0;
	--color-helbredsprofil-lime--20: #eef3d5;
	--color-helbredsprofil-lime--10: #f7f9ea;

	// Base overwrites
	--color-primary: var(--color-concern);
	--color-primary--dark: var(--color-concern--dark);
	--color-secondary: var(--color-klima);

	// Utilities colors
	--color-error: #c80000;
	--color-dark: #595959;
	--color-light: #f2f2f2;
	--color-transparent: #FFFFFF00;
	--color-black-transparent: #222222CC;

	// Border colors
	--color-border--gray: #94948f;
	--color-border--light: #dbdbdb;

	// Shadows
	--shadow-color--milder: rgba(17, 17, 17, 0.1);
	--shadow-color--mild: rgba(17, 17, 17, 0.2);
	--shadow-color--basemild: rgba(17, 17, 17, 0.2);
	--shadow-color--base: rgba(17, 17, 17, 0.5);

	//Implementation colors
	--color-text: var(--color-black);

	// Swiper colors
	--swiper-pagination-color: var(--color-concern) !important;
	--swiper-navigation-color: var(--color-concern) !important;
}
