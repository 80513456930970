@import "ui/styles/2-tools/tools.media-query.scss";
.Infobox{
    background-color: var(--theme-10);
    padding: 10%;
    margin-top: var(--spacing--md);
    margin-bottom: var(--spacing--md);
    width: 100%;
    max-width: var(--container-width--article-text);

    @include mq('md'){
        padding: 6%;
    }
}

.Icon{
    display: none;

    @include mq('md'){
        display: block;
        width: var(--icon-size--3xl);
        height: 100%;
        margin-right: 30px;
    }
}

.TitleContainer{
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing--md);
}

.TitleContainer > *{
    align-self: center;
}

.Text{
    color: var(--color-black);
    text-decoration: none;
}

.Text p{
    color: var(--color-black);
    margin-bottom: var(--spacing--sm);
}

.Text ul, .Text ol{
    margin-top: var(--spacing--sm);
    margin-left: var(--spacing--sm);
}

