@import "ui/styles/2-tools/tools.media-query.scss";

.MobileNavigation {
	z-index: var(--z-index--modal);
	@media print {
		display: none !important;
	}

}

.MobileNavigation_icons {
	display: flex;
	gap: var(--spacing--base);
	position: relative;
	top: -.9rem;


	svg {
		cursor: pointer;
	}
}

.MobileNavigation_searchIcon {
	&___isFrontPage {
		* {
			stroke: var(--color-white);
		}
	}
}

.MobileNavigation_search {
	position: absolute;
	width: 100%;
	background-color: var(--color-white);
	left: 0;
	top: 0;
	transform: translateY(-100%);
	display: flex;
	gap: var(--spacing--base);
	padding: 2.2rem var(--spacing--base) 6rem;
	z-index: 1;

	@include mq("sm") {
		padding: 2rem var(--spacing--base);
	}

	@include mq("header") {
		display: none;
	}

	&.enter {
		transform: translateY(-100%);
	}

	&.enterActive {
		transform: translateY(0);
		transition: all var(--trans-time--long) ease;
	}

	&.enterDone {
		transform: translateY(0);
	}

	&.exit {
		transform: translateY(0);
	}

	&.exitDone {
		transform: translate(-100%);
	}

	&.exitActive {
		transform: translateY(-100%);
		transition: all var(--trans-time--long) ease;
	}

	@include mq("md") {
		padding: 4rem var(--spacing--base);
	}
}

.MobileNavigation_searchCloseIcon {
	cursor: pointer;
	width: 44px;
	height: 44px;
}

.MobileNavigation_toggle {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	overflow: visible;
	cursor: pointer;
	height: 4.4rem;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: pointer;
}

.MobileNavigation_wrapper {
	position: fixed;
	width: 0;
	height: 100vh;
	background-color: var(--color-white);
	top: 0;
	left: 0;
	transform: translateX(100%);
	z-index: var(--z-index--modal);
	overflow-y: scroll;

	&.enter {
		transform: translateX(100%);
		width: 100vw;
	}

	&.enterActive {
		width: 100vw;
		transform: translateX(0);
		transition: all var(--trans-time--long) ease;
	}

	&.enterDone {
		transform: translateX(0);
		width: 100vw;
	}

	&.exit {
		width: 100vw;
		transform: translateX(0);
	}

	&.exitDone {
		width: 0;
		transform: translate(100%);
	}

	&.exitActive {
		width: 100vw;
		transform: translateX(100%);
		transition: all var(--trans-time--long) ease;
	}
}

.MobileNavigation_toolsDrawer {
	position: absolute;
	display: flex;
	width: 100%;
	min-height: 86px;
	justify-content: space-between;
	padding: 2.2rem var(--spacing--base);
	background-color: var(--color-white);
	z-index: 2;

	&___isActive {
		overflow: auto;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	}
}

.MobileNavigation_languageSelector {
	margin-top: 0.7rem;
}

.MobileNavigation_menu {
	position: absolute;
	top: 86px;
	width: 100%;
}