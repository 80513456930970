@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.action.scss";

.RichText {
	h1 {
		font-family: var(--font-primary);
		font-weight: var(--font-weight--medium);
		font-size: var(--font-size--7xl);
		line-height: var(--line-height--4xl);
		color: var(--color-concern);

		@include mq("lg") {
			font-size: var(--font-size--8xl);
			line-height: var(--line-height--6xl);
		}
	}

	h2 {
		font-family: var(--font-primary);
		font-weight: var(--font-weight--semibold);
		font-size: var(--font-size--6xl);
		line-height: var(--line-height--3xl);
		color: var(--color-black);
		margin-bottom: var(--spacing--md);
		margin-top: var(--spacing--xl);

		@include mq("lg") {
			margin-top: var(--spacing--2xl);
			font-size: var(--font-size--7xl);
			line-height: 4.5rem;
		}
	}

	h3 {
		font-family: var(--font-primary);
		font-weight: var(--font-weight--semibold);
		font-size: var(--font-size--4xl);
		line-height: 3.2rem;
		color: var(--color-black);
		margin-bottom: var(--spacing--md);
		margin-top: var(--spacing--2xl);

		@include mq("lg") {
			font-size: var(--font-size--6xl);
			line-height: var(--font-size--7xl);
		}
	}

	h4 {
		font-family: var(--font-primary);
		font-weight: var(--font-weight--semibold);
		font-size: var(--font-size--xl);
		line-height: var(--font-size--2xl);
		color: var(--color-black);
		margin-bottom: var(--spacing--md);
		margin-top: var(--spacing--2xl);

		@include mq("lg") {
			font-size: var(--font-size--5xl);
			line-height: var(--line-height--2xl);
		}
	}

	h5 {
		font-family: var(--font-primary);
		font-weight: var(--font-weight--semibold);
		font-size: var(--font-size--lg);
		line-height: var(--font-size--lg);
		color: var(--color-black);
		margin-bottom: var(--spacing--sm);
		margin-top: var(--spacing--2xl);

		@include mq("lg") {
			font-size: 2.3rem;
			line-height: var(--line-height--xl);
		}
	}

	h6 {
		font-family: var(--font-primary);
		font-weight: var(--font-weight--bold);
		font-size: var(--font-size--md);
		line-height: var(--font-size--2xs);
		color: var(--color-black);
		margin-top: var(--spacing--lg);

		@include mq("lg") {
			font-size: var(--font-size--xl);
			line-height: var(--line-height--sm);
		}
	}

	p {
		@include paragraph--md;
		margin-bottom: var(--spacing--md);
	}

	a {
		color: var(--color-concern);
		@include external-link;
	}

	li {
		@include bullet-list;
		line-height: var(--line-height--md);
	}

	:global(.download-link-richtext.button-link a){
		@include download-link-richtext;
	}

	:global(.table-responsive ) {
		width: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		margin: 2rem 0 3rem;
	}

	img[style*="left"],
	img[align*="left"]{
		margin: 0 1.3rem .3rem 0;
	}

	img[style*="right"],
	img[align*="right"]{
		margin: 0 0 .3rem 1.3rem;
	}

	table {
		//border: .1rem solid transparent;
		border-collapse: collapse;
		//table-layout: inherit;

		th, td {
			border: .1rem solid var(--color-border--gray);
			padding: var(--spacing--base);
			
		}

		td {
			vertical-align: top;
		}

		// the following is only for removing outer borders
		th {
			border-top: none;
			text-align: left;
		}

		td:first-of-type,
		th:first-of-type {
			border-left: none;
		}

		td:last-of-type,
		th:last-of-type {
			border-right: none;
		}

		tr {
			&:last-of-type td{
				border-bottom: none;
			}
		}
	}
}
