@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.UrgentContact {
	padding: var(--spacing--4xl) var(--spacing--xs);
	position: relative;
	background-color: var(--theme-10);

	@include mq("md") {
		padding: var(--spacing--4xl) var(--spacing--md);
	}

	@include mq("lg") {
		padding: 12rem 17rem 12rem 21rem;
	}
}

.UrgentContact_container {
	@include mq("lg") {
		padding-left: 0;
	}
}

.UrgentContact_header {
	padding-bottom: var(--spacing--4xl);
	@include mq("md") {
		padding-bottom: var(--spacing--5xl);
		display: flex;
		justify-content: space-between;
	}
}

.UrgentContact_headingContainer,
.UrgentContact_buttonContainer {
	display: grid;
	align-items: center;
}

.UrgentContact_heading {
	@include mq("md") {
		font-size: var(--font-size--9xl);
		line-height: var(--line-height--6xl);
	}
}

.UrgentContact_button {
	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}

	&___mobile {
		width: 100%;
		display: block;
		@include mq("md") {
			display: none;
		}
	}
}

.UrgentContact_content {
	list-style-type: none;
	@include mq("md") {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--spacing--lg);
	}
}
