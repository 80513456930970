/* ------------------------------------ *\
		settings.typography
\* ------------------------------------ */

:root {
	// Font family
	--font-primary: "Inter", sans-serif;
	--font-secondary: "Open Sans", sans-serif;

	// Font weights
	--font-weight--lighter: 200;
	--font-weight--light: 300;
	--font-weight--normal: 400;
	--font-weight--medium: 500;
	--font-weight--semibold: 600;
	--font-weight--bold: 700;
	--font-weight--black: 800;
	--font-weight--extrablack: 900;

	// Font sizes
	--font-size--2xs: 1.3rem;
	--font-size--xs: 1.4rem;
	--font-size--sm: 1.5rem;
	--font-size--md: 1.6rem;
	--font-size--lg: 1.8rem;
	--font-size--xl: 2rem;
	--font-size--2xl: 2.2rem;
	--font-size--3xl: 2.2rem;
	--font-size--4xl: 2.4rem;
	--font-size--5xl: 2.8rem;
	--font-size--6xl: 3.2rem;
	--font-size--7xl: 3.8rem;
	//--font-size--7xl: 3.6rem;
	--font-size--8xl: 4.6rem;
	--font-size--9xl: 4.8rem;
	--font-size--10xl: 7.2rem;
	--font-size--11xl: 9.6rem;

	// Line heights
	--line-height--3xs: 1.3rem;
	--line-height--2xs: 1.8rem;
	--line-height--2xs: 2rem;
	--line-height--xs: 2.2rem;
	--line-height--sm: 2.4rem;
	--line-height--md: 2.6rem;
	--line-height--lg: 2.8rem;
	--line-height--xl: 3rem;
	--line-height--2xl: 3.4rem;
	--line-height--3xl: 4rem;
	--line-height--4xl: 4.6rem;
	--line-height--5xl: 5.6rem;
	--line-height--6xl: 5.8rem;
	--line-height--7xl: 8.2rem;
	--line-height--8xl: 10.2rem;
}
