@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.action.scss";

.FooterNavigation {
	display: none;
	padding: 15rem 0 3rem;
	background-color: var(--color-primary);

	@include mq("md") {
		display: block;
	}
}

.FooterNavigation_headerLink {
	font-size: var(--font-size--xl);
	line-height: var(--line-height--sm);
	margin-bottom: 1.6rem;
	color: var(--color-white);
	text-decoration: none;
	display: inline-block;
	@include link-hover-animation(var(--color-white));
}

.FooterNavigation_list {
	margin: 0 0 9.6rem 0;
	padding: 0;
	list-style: none;
	color: var(--color-white);
}

.FooterNavigation_levelTwoLinkIcon {
	position: relative;
	top: 0.7rem;
	left: 0.3rem;
	opacity: 0;
	transition: transform ease-in 0.4s, opacity ease-in 0.2s;
	stroke: var(--color-white);
	stroke-width: 1.5;
}

.FooterNavigation_levelTwoLink {
	@include paragraph--md;
	color: var(--color-white);
	text-decoration: none;
	margin-bottom: 0.8rem;
	display: inline-block;
	padding-right: 2.9rem;

	&:hover {
		// TODO: we have to talk to design about this
		//@include pseudo-hover();
		font-weight: var(--font-weight--semibold);
		.FooterNavigation_levelTwoLinkIcon {
			opacity: 1;
			transform: translateX(0.4rem);
		}
	}
}

.Footer_serviceNavContainer {
	display: none;

	@include mq("md") {
		display: block;
	}
}
