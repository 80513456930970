@import "ui/styles/2-tools/tools.action";

.CtaButton {
    margin: 16px 0px 48px 0px;
}

.ctaButtonContainer {
    display: flex;
    flex-direction: column;
    &__left{
        align-items: flex-start;
    }
    &__center{
        align-items: center;
    }
    &__right{
        align-items: end;
    }
}

