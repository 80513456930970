.Slider {
	width: 100%;
}

.Slider_wrapper {
	color: red;
}

.Slider_slide {
	background: var(--color-primary);
	color: var(--color-light);
	padding: var(--spacing--base);
	height: 30rem;
}
