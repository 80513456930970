@import "ui/styles/2-tools/tools.action";

.VideoListTag {
	width: 100%;
}

.VideoListTag_heading {
	margin-bottom: var(--spacing--xl);
}

.VideoListTag_totalVideos {
	font-size: var(--font-size--4xl);
	margin-left: 2rem;
}

.VideoListTag_list {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--spacing--xl);

	@include mq("sm") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mq("md") {
		grid-template-columns: repeat(4, 1fr);
	}
}

.VideoListTag_listMedia {
	margin-bottom: var(--spacing--sm);
}

.VideoList_listLink {
	display: inline-block;
	color: var(--color--dark);
	font-size: var(--font-size--xl);
	margin-top: .5rem;

	&:hover {
		text-decoration: underline;
	}
}
