@import "ui/styles/2-tools/tools.media-query.scss";

.u-mobile-hidden {
    display: none;

    @include mq("md") {
		display: block;
	}
}

.u-desktop-hidden {
    display: block;

    @include mq("md") {
		display: none;
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
