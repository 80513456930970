@import "ui/styles/2-tools/tools.media-query.scss";

.HeroCard {
	@include mq("md") {
		min-height: 350px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 0;
	}
	
	&___hasNoImage {
		display: block;
		min-height: 0;

		.HeroCard_textboxContainer_inner {
			padding-left: var(--spacing--base);
			padding-right: var(--spacing--base);
		}
	}

	&___fullWidthImage {
		display: flex;
		flex-direction: column;

		.HeroCard_imageContainer{
			padding-top: 0px;
		}

		.HeroCard_videoContainer{
			display: flex;
			width: 100%;
			flex-direction: column-reverse;
			max-height: 70rem;
			object-fit: cover;
			padding-top: 0px;
			padding-bottom: 0px;
			overflow: hidden;
		}

		@include mq('base'){

			.HeroCard_imageContainer{
				padding-top: 0px;
				position: relative;
			}

			.HeroCard_videoContainer{
				position: relative;
				padding: 0;
			}

			.HeroCard_overlay {
				position: absolute;
				top: 0%;
				width: 100%;
				height: 80%;
				background: linear-gradient(173deg, var(--color-black-transparent) 30%, var(--color-transparent) 70%);
				pointer-events: none;
			
				@media print {
					display: none !important;
				}
			}

			.HeroCard_textboxContainer{
				position: absolute;
				background-color: transparent!important;
				width: 60%;
				margin-left: 113px;
				margin-top: 70px;
				padding: 0;
			}

			.HeroCard_heading{
				color: var(--color-white);
			}

			.HeroCard_manchet{
				color: var(--color-white);
			}

			.HeroCard_textboxContainer_inner{
				padding: 0px;
			}

			.HeroCard_contactAnchorLink {
				color: var(--color-white);
			}
		}
	}
}

.HeroCard_imageContainer {
	display: flex;
	width: 100%;
	flex-direction: column-reverse;
	max-height: 70rem;
	object-fit: cover;

	@include mq("md") {
		height: 50rem;
		display: block;
		padding-top: var(--spacing--3xl);
	}

	@include mq("lg") {
		height: 70rem;
	}

	@media print {
		display: none !important;
	}
}

.HeroCard_videoContainer {
	display: flex;
	width: 100%;
	flex-direction: column-reverse;
	max-height: 70rem;
	object-fit: cover;

	@include mq("md") {
		display: block;
		padding: var(--spacing--lg) 0 var(--spacing--3xl);
	}
}

.HeroCard_breadcrumb {
	padding-top: var(--spacing--base);

	&___hasNoImage {
		display: none;
	}
}

.HeroCard_textboxContainer {
	width: 100%;
	padding: var(--spacing--lg) var(--spacing--xs) var(--spacing--xl) var(--spacing--xs);
	display: grid;
	align-items: center;
	margin: 0;

	@include mq("md") {
		padding: var(--spacing--lg) var(--spacing--lg) var(--spacing--xl) var(--spacing--lg);
		margin-bottom: var(--spacing--3xl);
		
		&___mediaAllignBottom {
				margin-bottom: 0;
			}

		&___hasNoImage {
			margin-bottom: 0;
			display: block;
		}
	}

	@include mq("lg") {
		padding-left: var(--spacing--4xl);
	}

	&___hasNoImage {
		background-color: transparent;
		padding-left: var(--spacing--sm);

		@include mq("lg") {
			padding-left: var(--spacing--lg);
		}
	}

	&___hasVideo {
		margin-bottom: 0;
	}
}

.HeroCard_heading {
	margin-block-end: var(--spacing--xl);
	word-wrap: break-word;
	font-size: var(--font-size--7xl);
	line-height: 1.2;

	overflow-x: hidden;
	@include mq("sm") {
		overflow-x: initial;
		font-size: var(--font-size--10xl);
		line-height: 9rem;
	}
}

.HeroCard_contactAnchorLink {
	margin-top: var(--spacing--sm);
	color: var(--color-black);
}

.HeroCard_NavLink {
	margin-top: var(--spacing--sm);
}
