@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";

.ContentCard {
	padding-bottom: calc(var(--grid-gutter) * 10);
	padding-right: calc(var(--grid-gutter) * 2);

	@include mq("md") {
		padding-bottom: calc(var(--grid-gutter) * 4);
		padding-right: 0px;
	}
}

.ContentCard_media {
	margin-bottom: var(--spacing--md);

	&___withBorder {
		border: 1.6rem solid transparent;
	}
}

.ContentCard_mediabox {
	@include aspect-ratio(16, 9);
}

.ContentCard_textbox {
	padding-left: var(--spacing--sm);

	@include mq("md") {
		padding-left: 0;
	}
}

.ContentCard_heading {
	padding-bottom: var(--spacing--md);
}

.ContentCard_multiLinks {
	list-style-type: none;
	padding-bottom: var(--spacing--xs);
	li {
		padding-bottom: var(--spacing--md);
	}
}

.ContentCard_multiLink {
	@include paragraph--md;
	text-decoration: none;

	display: flex;
	//padding: var(--spacing--xs) 0;
	gap: var(--spacing--sm);
	text-decoration: none;

	transition: var(--trans-time--long);
	&:hover {
		transform: translateX(0.4rem);
	}
}

.ContentCard_richText {
}

.ContentCard_singleLink {
	@include paragraph-caption--lg;
	text-decoration: underline;
	text-transform: uppercase;
	font-weight: var(--font-weight--medium);
}

.ContentCard_iconWrapper {
	display: grid;
	align-items: center;

	@include mq("md") {
		padding-right: var(--spacing--sm);
	}
}

.ContentCard_icon {
	stroke: var(--color-primary);
	stroke-width: 1.5;
}
