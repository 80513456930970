@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

@import "ui/styles/2-tools/tools.media-query.scss";

.DocumentList_container {
	@include mq("md", "max") {
		padding: 0 !important;
	}
}

.DocumentList {
	margin-bottom: var(--spacing--module-bottom);
	padding: var(--spacing--4xl) 0;

	@include mq("md") {
		padding: var(--spacing--3xl) 0;
	}

	@include mq("lg") {
		padding: var(--spacing--4xl) 0;
		margin-bottom: var(--spacing--4xl);
	}
}

.DocumentList_heading {
	padding-bottom: var(--spacing--md);

	@include mq("md") {
		font-size: var(--font-size--9xl);
	}
}

.DocumentList_links {
	list-style-type: none;
	border-bottom: 1px solid var(--color-gray);
}

.DocumentList_link {
	display: flex;
	justify-content: space-between;
	padding: var(--spacing--lg) 0;
	gap: var(--spacing--sm);
	text-decoration: none;
	color: var(--color-black);
	transition: var(--trans-time--long);

	&:hover {
		transform: translateX(0.4rem);
	}
}

.DocumentList_iconWrapper {
	display: grid;
	align-items: center;

	@include mq("md") {
		padding-right: var(--spacing--sm);
	}
}

.DocumentList_icon {
	stroke: var(--color-primary);
	stroke-width: 1.5;
}
