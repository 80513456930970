@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.ServiceNavigation {
	@include reset-list;
	margin-left: 2.5rem;
	margin-top: 2.5rem;

	@include mq("header") {
		margin: 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	@include mq("md", "max") {
		// So it doesnt cut off at Iphone Safari
		margin-bottom: 100px;
	}
}

.ServiceNavigation_item {
	margin-bottom: var(--spacing--sm);

	@include mq("md") {
		margin-right: var(--spacing--xl);
		margin-bottom: 0;
	}

	&:last-child {
		@include mq("header") {
			margin-right: 0;
		}
	}
}

.ServiceNavigation_link {
	font-size: var(--font-size--sm);
	line-height: var(--line-height--md);
	color: var(--color-dark);
	text-decoration: none;

	&.isFrontPage {
		color: var(--color-white);
	}

	&:hover {
		text-decoration: underline;
	}
}
