@import "ui/styles/2-tools/tools.media-query.scss";

.HeroCardCludo {
	margin-bottom: var(--spacing--4xl);
	padding-top: 7rem;
	padding-bottom: 10rem;


	@include mq("md") {
		padding-bottom: 14rem;
		margin-bottom: 0;
	}

	@media print {
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.HeroCardCludo_breadcrumb {
	padding-top: var(--spacing--base);
}

.HeroCardCludo_textboxContainer {
	width: 100%;
}

.HeroCardCludo_textbox__inner {
	@include mq("md") {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.HeroCardCludo_headingButtons {
	text-align: right;
	min-width: 10rem;
}

.HeroCardCludo_link {
	margin-bottom: var(--spacing--lg);
	position: relative;
	left: -.3rem;
	min-width: 30rem;
	margin-left: auto;
	padding: var(--spacing--xs) var(--spacing--md);

	@include mq("md", 'max') {
		padding: var(--spacing--sm) var(--spacing--lg);
		width: 100%;

	}
	@include mq("sm") {
		max-width: 50%;
	}
	@media print {
		display: none !important;
	}
}

.HeroCardCludo_heading {
	margin-block-end: var(--spacing--lg);
	margin-left: -.3rem;
	font-size: var(--font-size--7xl);
	line-height: 1.2;

	@include mq("sm") {
		overflow-x: initial;
		font-size: var(--font-size--10xl);
		line-height: 9rem;
	}
}

.HeroCardCludo_contactAnchorLink {
	margin-top: var(--spacing--sm);
	color: var(--color-black);
}
.HeroCardCludo_NavLink {
	margin-top: var(--spacing--sm);
}
