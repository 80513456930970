@import "ui/styles/2-tools/tools.action";

.Link {
	@include action;
	text-decoration: none;

	&___sm {
		font-size: var(--font-size--sm);
	}

	&:hover {
		.Link_arrowIcon {
			transform: translateX(0.4rem);
		}
	}
}

.Link_arrowIcon {
	position: relative;
	top: 0.7rem;
	left: 0.7rem;
	transition: transform ease-in 0.2s, opacity ease-in 0.2s;
	stroke: var(--color-primary);
	stroke-width: 1.5;
}
