@import "ui/styles/2-tools/tools.list-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.SocialLinks {
	@include reset-list;
	display: flex;
}

.SocialLinks_item:not(:last-child) {
	margin-right: var(--spacing--base);
}

.SocialLinks_link {
	color: var(--color-primary);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}



.SocialLinks_icon {
	width: 4.8rem;
	height: 4.8rem;
	color: var(--color-white);
	
	svg {
		stroke: var(--color-primary);
		stroke-width: 1.5;
		@include transition {
			transition: color ease-in  var(--trans-time--shortest);
		}
	}

	&:hover {
		svg {
			color: var(--color-primary);
			stroke: var(--color-white);
		}
	}
}
