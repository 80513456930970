@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.SearchField {
	@include mq("md") {
		display: grid;
		align-items: center;
	}

	&.hasError {
		color: var(--color-error);
	}

	&.isDisabled {
		color: var(--color-gradient--5);
	}
}

.SearchField_container {
	//	position: relative;
	margin: 0 auto;

	@include mq("md") {
		margin-bottom: 0.5em;
	}
}

.SearchField_wrapper {
	position: relative;
	@include mq("md") {
		display: flex;
		width: 90%;
	}
}

.SearchField_input {
	input {
		border: var(--border--sm) solid var(--color-border--gray);
		color: var(--color-primary);
		width: 100%;
		margin: 0;
		height: 4.4rem;
		border-radius: 2.2rem;

		&:hover,
		&:focus {
			border-color: var(--color-primary);
		}

		@include mq("md") {
			padding: 0 var(--spacing--2xl) 0 var(--spacing--md);
			min-width: 70rem;
			width: 100%;
			height: 8rem;
			padding: 0 13rem 0 0;
			border: 0;
			border-radius: 0;
			border-bottom: var(--border--sm) solid var(--color-border--gray);
			font-size: 3.5rem;
			font-style: italic;

			&:hover,
			&:focus {
				border: none !important;
				border-bottom: var(--border--sm) solid var(--color-border--gray) !important;
			}
		}
	}

	label {
		display: none;
	}
}

.SearchField_iconWrapper {
	transition: all var(--trans-time--shorter);
	width: 2.4rem;
	height: 2.4rem;
	margin: 0 auto;
	border: var(--border--sm);
	stroke: var(--color-primary);
}

.SearchField_buttonContainer {
	@include mq("md", "max") {
		position: absolute;
		top: 0;
		right: 0;
	}

	@include mq("md") {
		display: grid;
		align-items: center;
		z-index: 2;
	}
}

.SearchField_button {
	margin-left: -12rem;
	height: 5.6rem;

	span {
		display: flex;
		gap: var(--spacing--xs);
	}

	&:hover,
	&:focus {
		.SearchField_icon {
			stroke: var(--color-white);
		}
		.SearchField_buttonText { 
			color: var(--color-white);
		}
	}

	@include mq("md", "max") {
		margin-left: -4.4rem;
		height: 4.4rem;
		width: 4.4rem;
		padding: 0;
		border: var(--border--sm) solid var(--color-primary);
	}
}

.SearchField_buttonText {
	color: var(--color-primary);
	display: none;
	@include mq("md") {
		display: block;
	}
}

.SearchField_resultsContainer {
	display: grid;
	align-items: center;
	padding: var(--spacing--lg) var(--spacing--md);
	grid-template-rows: repeat(5, 1fr);
	margin-top: var(--spacing--md);
	width: 100%;
	height: 300px;
	background-color: var(--color-white);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

	@include mq("md", "max") {
		padding: var(--spacing--sm);
		position: absolute;
		left: 0;
	}
}

.SearchField_result {
	@include heading--2xs;
	color: var(--color-black);
	list-style: none;
	padding: var(--spacing--xs) var(--spacing--md);
	cursor: pointer;
	border-radius: var(--corner-size--lg);

	&:hover {
		background-color: var(--color-concern--10);
	}

	@include mq("md", "max") {
		padding: var(--spacing--xs);
	}
}
