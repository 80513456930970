@import "ui/styles/2-tools/tools.media-query.scss";
.EventList_list {
	padding-top: var(--spacing--4xl);
}

.EventsList_heading {
	color: var(--color-concern);
	text-transform: capitalize;
	margin: 0 0 var(--spacing--sm) var(--grid-gutter);

	@include mq("md") {
		font-size: var(--font-size--9xl);
	}
}
