.Icon {
	display: flex;
	justify-content: center;
	align-items: center;

	&___sm {
		width: var(--icon-size--sm);
		height: var(--icon-size--sm);
	}

	&___md {
		width: var(--icon-size--md);
		height: var(--icon-size--md);
	}

	&___lg {
		width: var(--icon-size--lg);
		height: var(--icon-size--lg);
	}

	&___xl {
		width: var(--icon-size--xl);
		height: var(--icon-size--xl);
	}

	&___2xl {
		width: var(--icon-size--2xl);
		height: var(--icon-size--2xl);
	}

	&___3xl {
		width: var(--icon-size--3xl);
		height: var(--icon-size--3xl);
	}

	&___4xl {
		width: var(--icon-size--4xl);
		height: var(--icon-size--4xl);
	}

	&___circle {
		border: var(--border--sm) solid var(--color-border--gray);
		border-radius: 100%;
	}

	&___none {
		width: none;
		height: none;
	}

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}
