@import "ui/styles/2-tools/tools.typography-mixins.scss";

.FormPicker_wrapper {
	width: 100%;
	margin-block: var(--spacing--xl);
}

.FormPicker_heading {
	font-family: var(--font-primary);
    font-weight: var(--font-weight--semibold);
    font-size: var(--font-size--6xl);
    line-height: var(--line-height--3xl);
    color: var(--color-black);
    margin-bottom: var(--spacing--md);
    margin-top: var(--spacing--xl);
}

.FormPicker_feedbackLabel {
	@include formfield-label;
	padding-left: 0;
}