@import "ui/styles/2-tools/tools.typography-mixins.scss";

.VideoConsent_NotAccepted {
	position: relative;
	display: flex;
	padding: 20rem;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	background: var(--color-dark);
	color: var(--color-white);

	@include mq('md'){
		padding-top: 15rem, 60rem, 0rem, 0rem;
	}
}

.VideoConsent_NotAcceptedAction {
	display: block;
	color: var(--color-white);
	margin-bottom: var(--spacing--base);
}
