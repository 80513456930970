@import "ui/styles/2-tools/tools.media-query.scss";

.Search {
	width: 100%;
}

.Search_searchErrorText {
	color: var(--color-black);
	width: 80%;

	@include mq("xxs") {
		display: flex;
		justify-content: center;
	}
	@include mq("md") {
		width: 100%;
	}
}
