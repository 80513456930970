@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

.AgendaCard {
	padding: var(--spacing--lg) var(--spacing--xl);
	background-color: var(--color-gray--lighter);
	margin-bottom: var(--spacing--xl);
}
.AgendaCard_heading {
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--2xs);
	@include mq("lg") {
		font-weight: var(--font-weight--bold);
	}
}
.AgendaCard_kicker {
	padding-bottom: var(--spacing--base);
	font-weight: var(--font-weight--bold);
}
.AgendaCard_text {
	padding-bottom: var(--spacing--lg);
}
.AgendaCard_dateCircle {
	padding: var(--spacing--xl);
	text-align: center;
	background-color: var(--color-helbredsprofil);
	border: 0;
	p {
		line-height: 1.5rem;
		color: var(--color-white);
		font-size: var(--font-size--md)
	}
}
.AgendaCard_link {
	@include paragraph--md;
	text-decoration: underline;
	font-size: var(--font-size--base);
	color:var(--color-concern);
}
.AgendaCard_head{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: var(--spacing--xl);
	border-bottom: var(--border--sm) solid var(--color-gray);
	padding: var(--spacing--sm) 0;
}
.AgendaCard_dateCircle__inner{
	padding: 1.25rem;
}
.AgendaCard_heading__inner, .AgendaCard_dateCircle_inner {
margin-bottom: var(--spacing--lg);
}