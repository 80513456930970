@import "ui/styles/2-tools/tools.media-query.scss";

.u-with-leftnavigation-container {
  @include mq("base") {
    display: flex;
    gap: var(--spacing--3xl);
  }
}

.u-with-leftnavigation-content {
  @include mq("base") {
    padding-left: var(--spacing--3xl);
		width: calc(100% - 33rem);
	}
}

.u-no-leftnavigation-container [class*="ContentSection_Section"]{
  margin-left: -1.9rem;
}