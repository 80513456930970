@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Paragraph {
	&___manchet {
		@include paragraph-manchet;
	}

	&___sm {
		@include paragraph--sm;
	}

	&___md {
		@include paragraph--md;
	}

	&___mdSemibold {
		@include paragraph-semibold--md;
	}

	&___lg {
		@include paragraph--lg;
	}

	&___lgBold {
		@include paragraph-bold--lg;
	}

	&___xl {
		@include paragraph--xl;
	}

	&___lgCaption {
		@include paragraph-caption--lg;
	}

	&___smCaption {
		@include paragraph-caption--sm;
	}
	a {
		color: var(--color-black);
	}
}
