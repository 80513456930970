@import "ui/styles/2-tools/tools.media-query.scss";

.Pagination {
	list-style: none;
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing--sm);
	padding: var(--spacing--lg) var(--spacing--xs) 8rem;
	margin-top: var(--spacing--3xl);
	border-top: var(--border--sm) solid var(--color-gray);

	&___border-top {
		border-top: var(--border--sm) solid var(--color-gradient--20);
	}

	@include mq("md") {
		display: flex;
		padding: var(--spacing--lg) var(--spacing--xs) 7rem;
		gap: var(--spacing--4xl);
		justify-content: center;
	}

	@include mq("md", "max") {
		li:nth-last-child(1) {
			display: flex;
			justify-content: end;
		}
	}
}

.Pagination_item {
	display: grid;
	justify-content: center;
	align-items: center;

	&___active {
		font-weight: var(--font-weight--normal);
	}

	&___gutter {
		margin: 0 var(--spacing--lg);
	}

	&___mobile {
		display: none;

		@include mq("md") {
			display: inline-block;
		}
	}

	// Toggle class used in JS
	&___hide {
		display: none;
	}
}

.Pagination_linkWrapper {
	//.next {
		display: flex;
		justify-content: end;
	//}
}

.Pagination_link {
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: 50%;

	span {
		width: 100%;
		svg {
			fill: currentColor;
		}
	}

	&___active {
		background-color: var(--color-primary);
		color: var(--color-white);
	}
}

.PaginationReact_link {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: var(--border--md) solid var(--color-primary);
	text-align: center;
	border: var(--border--md) solid var(--color-primary);
	color: var(--color-primary);

	&:hover {
		background-color: var(--color-primary);
		color: var(--color-white);
	}

	&___active {
		background-color: var(--color-primary);
		color: var(--color-white);
	}

	&___disabled {
		cursor: default;
		border: var(--border--md) solid var(--color-gray);
		color: var(--color-gray);

		&:hover {
			background-color: var(--color-white);
			color: var(--color-gray);
		}
	}

	svg {
		fill: currentColor;
	}
}
