@import "ui/styles/2-tools/tools.media-query.scss";

.SubsiteBar {
	position: relative;
	top: -1.5em;
	display: flex;
	align-items: center;
	width: 100%;
	height: 6.5rem;
	background-color: var(--theme-base);

	.SubsiteBarContainer {
		margin-left: 0;
		margin-right: 0;
	}
}

.Subsitebar_asset {
	justify-content: left !important;
}
