/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

@import "ui/styles/2-tools/tools.media-query.scss";

html {
	font-family: var(--font-primary);
	font-size: 10px;
	scroll-behavior: smooth;

	scroll-padding-top: 10rem;
	@include mq("header") {
		scroll-padding-top: 25rem;
	}
}

body {
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	overflow-x: hidden;
}
