@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.action.scss";

.Contactbox {
	padding: var(--spacing--2xl) var(--spacing--xs);

	@include mq("md") {
		padding: var(--spacing--4xl) var(--spacing--md);
	}

	@include mq("lg") {
		padding: 12rem 21rem;
	}

	u {
		text-decoration: underline;
	}
}

.Contactbox_headingContainer {
	@include mq("md") {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		padding-bottom: var(--spacing--2xl);
	}
}
.Contactbox_levelTwoLink {
	@include paragraph--md;
	color: var(--color-concern--50);
	text-decoration: none;
	margin-bottom: 0.8rem;
	display: inline-block;
	padding-right: 2.9rem;

	&:hover {
		text-decoration: none;
	}
}
.Contactbox_alignMiddle {
	display: grid;
	align-items: center;
}

.Contactbox_heading {
	padding-bottom: var(--spacing--xl);
	@include mq("md") {
		padding-bottom: 0;
	}
}

.Contactbox_link {
	color: var(--color-black);
	text-decoration: underline;
	@include external-link;
}

.Contactbox_description {
	max-width: 100%;
	padding-bottom: var(--spacing--4xl);

	@include mq("md") {
		max-width: 50%;
		padding-bottom: var(--spacing--4xl);
		font-size: var(--font-size--lg);
		line-height: var(--line-height--lg);
	}
}

.Contactbox_content {
	justify-content: space-between;
	@include mq("md") {
		display: flex;
	}
}

.Contactbox_addressCard,
.Contactbox_phoneCard,
.Contactbox_emailCard {
	padding-bottom: var(--spacing--2xl);
	width: 100%;
	@include mq("md") {
		width: 30%;
		padding-bottom: 0;
	}
}

.Contactbox_emailLink {
	a {
		color: var(--color-primary);
	}
}

.Contactbox_addressCard {
	width: 100%;
	@include mq("md") {
		width: 20%;
	}
}

.Contactbox_otherText {
	margin-bottom: var(--spacing--lg);
}

.Contactbox_flexContainer {
	display: flex;
	gap: var(--spacing--sm);
	flex-wrap: wrap;

	&___icon {
		padding-bottom: var(--spacing--xl);
	}
}

.Contactbox_flexItem {
	width: 70px;
}

.Contactbox_contentTitle {
	padding: var(--spacing--xl) 0 var(--spacing--xs);

	&___smallMargin {
		padding-top: var(--spacing--sm);
	}
}

.Contactbox_phoneNumber {
	color: inherit;
	text-decoration: none;
	&___mobile {
		width: 100%;
		display: block;
		margin: var(--spacing--xl) 0 var(--spacing--2xl);
		@include mq("md") {
			display: none;
		}
	}

	&___desktop {
		display: none;
		@include mq("md") {
			display: block;
		}
	}
}

.Contactbox_sundhedsplatformTekst {
	margin-top: var(--spacing--md);
}