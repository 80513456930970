.ArticleByline {
	margin-bottom: 3em;
}

.ArticleByline_name,
.ArticleByline_title {
	font-size: var(--font-size--sm);
	text-transform: uppercase;
	line-height: var(--line-height--4xl);
	font-weight: var(--font-weight--normal);
}
