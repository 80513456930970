/* ------------------------------------ *\
		settings.sizes
\* ------------------------------------ */

:root {
	// Icons
	--icon-size--base: 1rem;

	--icon-size--xs: calc(var(--icon-size--base) * 0.4);
	--icon-size--sm: calc(var(--icon-size--base) * 0.8);
	--icon-size--md: calc(var(--icon-size--base) * 2);
	--icon-size--lg: calc(var(--icon-size--base) * 3);
	--icon-size--xl: calc(var(--icon-size--base) * 4);
	--icon-size--2xl: calc(var(--icon-size--base) * 6);
	--icon-size--3xl: calc(var(--icon-size--base) * 8);
	--icon-size--4xl: calc(var(--icon-size--base) * 10);

	--container-width--standard: 124.8rem;
	--container-width--article-text: 72rem;
}
