@import "ui/styles/2-tools/tools.display-mixins";
@import "ui/styles/2-tools/tools.media-query.scss";

.Logo {
	display: inline-block;
	text-decoration: none;
	color: currentColor;

	&:global(.logo-smaller img){
		height: 5.5rem;
		@include mq("header") {
			margin-top: 1rem;
			height: 5.5rem;
		}
	}

	&:global(.logo-small img){
		height: 5.5rem;
		@include mq("header") {
			margin-top: 1rem;
			height: 7rem;
		}
	}

	&:global(.logo-koncern img){
		height: 5.5rem;

		@include mq("header") {
			height: 10.2rem;
		}
	}
}



.Logo_heading {
	@include hidden-absolute;
}

.Logo_asset {
	display: block;


	&___centered {
		display: grid;
		align-items: center;
	}

	// Fallback if no logosize is set
	img {
		height: 5.5rem;

		@include mq("header") {
			height: 8.5rem;
		}
	}
}
