@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.FormfieldCheckboxSelect {
	position: relative;
}

.FormfieldCheckboxSelect_input {
	display: flex;
	gap: var(--spacing--base);
	justify-content: space-between;
	cursor: pointer;
	position: relative;
	width: 100%;

	@include paragraph--lg;
	color: var(--color-gray);
	padding: var(--spacing--sm) var(--spacing--base) var(--spacing--sm) var(--spacing--lg);
	background: var(--color-white);
	outline: none;
	border-radius: 28px;
	border: 0.15rem solid var(--color-white);

	&:hover,
	&:focus {
		border: 0.15rem solid var(--color-primary);
	}

	&___isActive {
		border-radius: 28px 28px 0 0;
		border: 0.15rem solid var(--color-primary);
	}
}
.FormfieldCheckboxSelect__used__filters{
	margin-left: var(--spacing--2xs);
	&:first-of-type{
		margin-left: var(--spacing--md);
	}
}
.FormfieldCheckboxSelect_icon {
	@include transition {
		transition: all var(--trans-time--long);
	}

	&___isActive {
		transform: rotate(180deg);
	}
}

.FormfieldCheckboxSelect_resultsContainer {
	position: absolute;
	padding: 0 var(--spacing--sm) var(--spacing--sm) var(--spacing--sm);

	width: 100%;
	background-color: var(--color-white);
	border-radius: 0 0 28px 28px;
	margin-top: -2px;
	z-index: 2;
	border: 0.15rem solid var(--color-primary);
	border-top: none;
	list-style-type: none;

	/* 	@include mq("md", "max") {
		padding: var(--spacing--sm);
	} */

	label,
	li {
		cursor: pointer;
	}
}

.FormfieldCheckboxSelect_results {
	border: none;
	color: none;
	background: none;

	display: flex;
	gap: var(--spacing--base);
	align-items: center;

	@include paragraph--lg;
	color: var(--color-black);
	padding: var(--spacing--xs) var(--spacing--sm);

	/* @include mq("md", "max") {
		padding: var(--spacing--xs);
	} */

	label {
		width: 100%;
	}
}

.FormfieldCheckboxSelect_checkboxInput {
	height: 100%;
	&:focus-within {
		outline: 1px solid black;
	}
}
